import React, { Component, Fragment } from "react";

import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { LOGO_VERIFY } from "./../../config/config";
import { verfiyDomain } from "../../config/api_calls";
import HelperClass from "./../../config/helperClass";
import { tableLoader } from "../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import LOGO_ROUND from "../../assets/images/logo_round.png";
import PinInput from "react-pin-input";
const auth = new HelperClass();
export class GetPincode extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = async () => {
    const { values, verifyPincode } = this.props;
    await verifyPincode();
  };

  resendPin = async () => {
    const { values, resendPincode } = this.props;
    await resendPincode();
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    const { values, handleChangePin, handleChangePinComplete } = this.props;
    console.log("this.state.selected_email: ", values.selected_email);
    return (
      <>
        <div className="nk-block-head mt-4">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title">Enter the pin code</h4>
          </div>
        </div>

        <div className="d-flex justify-content-center  mb-4">
          <div className="user-card bg-lighter p-3 rounded-pill">
            <div className="user-avatar" style={{ background: "#fff" }}>
              <img src={LOGO_ROUND} alt="" />
            </div>
            <div className="user-name">
              <span
                className="tb-lead fs-16px"
                style={{ fontFamily: "Nunito, sans-serif" }}
              >
                {values.selected_email}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group  d-flex justify-content-center">
          <PinInput
            length={6}
            initialValue=""
            focus
            onChange={(value, index) => {
              handleChangePin(value, index, "");
            }}
            onCopy={(value, index) => {
              handleChangePinComplete(value, index, "");
            }}
            onBlur={(value, index) => {
              handleChangePin(value, index, "");
            }}
            onKeyDown={(value, index) => {
              handleChangePin(value, index, "");
            }}
            onComplete={(value, index) => {
              handleChangePinComplete(value, index, "");
            }}
            defaultValue={values.pincode}
            id="pincode"
            name="pincode"
            type="numeric"
            inputMode="number"
            style={{ padding: "15px" }}
            inputStyle={{ borderColor: "#dbdfea" }}
            inputFocusStyle={{ borderColor: "#6576ff" }}
            autoSelect={false}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>

        <div className="form-group">
          <div className="form-label-group">
            <label className="form-label" htmlFor="password">
              &nbsp;
            </label>
            {values.show_resend_button ? (
              <a
                onClick={this.resendPin}
                style={{
                  cursor: "pointer",
                  color: "#798bff",
                }}
              >
                {values.resendPinButton ? (
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : null}
                Resend Pin Code{" "}
              </a>
            ) : (
              <p>Resend the pin code in {values.countdown} seconds.</p>
            )}
          </div>
        </div>
        <hr />
        <div className="form-group d-flex justify-content-end">
          <button
            className="btn btn-lg btn-outline-light"
            disabled={this.state.disabled}
            onClick={this.back}
            style={{ marginRight: "10px" }}
          >
            Back
          </button>

          {values.verifyPasswordButton ? (
            <button className="btn btn-lg btn-primary" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Processing...</span>
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary"
              disabled={this.state.disabled}
              onClick={this.submitForms}
            >
              Next
            </button>
          )}
        </div>
      </>
    );
  }
}

export default GetPincode;
