import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { LOGO_VERIFY } from "./../../config/config";
import { verfiyDomain } from "../../config/api_calls";
import HelperClass from "./../../config/helperClass";
import { tableLoader } from "../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";
import LOGO_ROUND from "../../assets/images/logo_round.png";
const auth = new HelperClass();
export class GetPassword extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = async () => {
    const { values, checkPassword } = this.props;
    await checkPassword();
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  render() {
    const { values, handleChange } = this.props;
    console.log("this.state.selected_email: ", values.selected_email);
    return (
      <>
        <div className="nk-block-head">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title">Welcome</h4>
          </div>
        </div>

        <div className="d-flex justify-content-center  mb-4">
          <div className="user-card bg-lighter p-3 rounded-pill">
            <div className="user-avatar" style={{ background: "#fff" }}>
              <img src={LOGO_ROUND} alt="" />
            </div>
            <div className="user-name">
              <span
                className="tb-lead fs-16px"
                style={{ fontFamily: "Nunito, sans-serif" }}
              >
                {values.selected_email}
              </span>
            </div>
          </div>
        </div>
        <form method="post">
          <div className="form-group">
            <TextField
              required
              id="password"
              name="password"
              type="password"
              label="Enter your password"
              defaultValue={values.password}
              inputProps={{ maxLength: 50 }}
              onChange={handleChange("password")}
              helperText="Enter your password"
              variant="outlined"
              fullWidth
              autoFocus
            />
          </div>

          <div className="form-group d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-lg btn-outline-light"
              disabled={this.state.disabled}
              onClick={this.back}
              style={{ marginRight: "10px" }}
            >
              Back
            </button>

            {values.verifyPasswordButton ? (
              <button className="btn btn-lg btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={this.state.disabled}
                onClick={this.submitForms}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </>
    );
  }
}

export default GetPassword;
