import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { COMPANY_NAME, THEME_COLOR } from "../../config/config";
import { HTML5_FMT } from "moment";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";

export class VerifyUsername extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      verifyEmailButton: false,
    };
  }

  SubmitFirst = async () => {
    const { values, checkUsername } = this.props;
    console.log("this.state.email: ", values.selected_email);
    let firstResult = await checkUsername();
  };

  render() {
    const { values, handleChange, checkUsername, addMenuHistory } = this.props;

    return (
      <>
        <div className="nk-block-head mt-3 mb-3">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title">Create a {COMPANY_NAME} Account</h4>
            <div className="nk-block-des"></div>
          </div>
        </div>
        <form method="post">
          <div className="form-group">
            <TextField
              required
              id="username"
              name="username"
              type="text"
              label="Enter your username"
              value={values.username}
              inputProps={{ maxLength: 64 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/gi, "");
              }}
              onChange={handleChange("username")}
              helperText="only letters and numbers, 2 to 64 characters"
              variant="outlined"
              autoFocus
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              required
              id="email"
              name="email"
              type="text"
              label="Email Address"
              value={values.email}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange("email")}
              helperText="Please provide a real email address, you'll get a verification mail with an activation code."
              variant="outlined"
              fullWidth
            />
          </div>

          <hr />
          <div className="form-group text-right">
            {values.VerifyUsernameButton ? (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
                disabled={this.state.disabled}
                onClick={this.SubmitFirst}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </>
    );
  }
}

export default VerifyUsername;
