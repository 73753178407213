import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordStrengthBar from "react-password-strength-bar";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LOGO_ROUND from "../../assets/images/logo_round.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";

import {
  COMPANY_NAME,
  COMPANY_WEBSITE,
  RESELLER_TERMS_URL,
  RESELLER_PRIVACY_URL,
} from "../../config/config";

export class GetUserdeteil extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      successMessage: "",
      tableLoader: false,
      dnsList: "",
      suggestions: [],
      showPassword: false,
      showConfirmPassword: false,
      accept_terms: false,
      errorAcceptTerms: "",
    };
  }

  continue = (e) => {
    e.preventDefault();
    //PROCESS FORM//
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = async (e) => {
    e.preventDefault();
    const { values, formSubmit } = this.props;

    await formSubmit();
  };

  handleClickShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({
        showPassword: false,
      });
    } else {
      this.setState({
        showPassword: true,
      });
    }
  };
  handleClickShowConfirmPassword = () => {
    if (this.state.showConfirmPassword) {
      this.setState({
        showConfirmPassword: false,
      });
    } else {
      this.setState({
        showConfirmPassword: true,
      });
    }
  };

  handleChanges = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.checked;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  verificationResult = () => {
    console.log("dnsList.DNSData.dnsRecords", this.props);
    /*if (dnsList.ErrorMessage) {
      return (
        <div className="callout callout-danger">
          <h4>Error verifying the domain</h4>
          {dnsList.ErrorMessage.msg}
        </div>
      );
    } else if (dnsList.DNSData.dnsRecords) {
      return (
        <>
          {dnsList.DNSData.dnsRecords.map((dns) => (
            <p>{dns.type}</p>
          ))}
        </>
      );
    }*/
  };

  render() {
    const { values, handleChange, handleChangeCheckbox,handleChangePhoneNumber } = this.props;

    //console.log("Verification dnsList: ", values.selected_email);

    return (
      <>
        <div className="nk-block-head justify-content-center  mb-1">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title text-center">
              Enter the account details
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-center  mb-4">
          <div className="user-card bg-lighter p-3 rounded-pill">
            <div className="user-avatar" style={{ background: "#fff" }}>
              <img src={LOGO_ROUND} alt="" />
            </div>
            <div className="user-name">
              <span
                className="tb-lead fs-16px"
                style={{ fontFamily: "Nunito, sans-serif" }}
              >
                {values.selected_email}
              </span>
            </div>
          </div>
        </div>
        <form method="post">
          <div className="form-group">
            <TextField
              required
              id="company_name"
              name="company_name"
              type="text"
              label="Company Name"
              value={values.company_name}
              inputProps={{ maxLength: 64 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/gi, "");
              }}
              onChange={handleChange("company_name")}
              helperText="only letters and numbers plus spaces, 2 to 64 characters"
              error={values.errorCompName}
              variant="outlined"
              autoFocus
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="full_name"
              name="full_name"
              type="text"
              label="Full Name"
              value={values.full_name}
              inputProps={{ maxLength: 64 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/gi, "");
              }}
              onChange={handleChange("full_name")}
              helperText="only letters and numbers plus spaces, 2 to 64 characters"
              error={values.errorFullName}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="primary_contact_name"
              name="primary_contact_name"
              type="text"
              label="Primary Contact Name"
              value={values.primary_contact_name}
              inputProps={{ maxLength: 10, minLength: 7 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/gi, "");
              }}
              onChange={handleChange("primary_contact_name")}
              helperText="only letters and numbers plus spaces, 2 to 64 characters"
              error={values.errorContactName}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="notices_email"
              name="notices_email"
              type="email"
              label="Notices Email"
              value={values.notices_email}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange("notices_email")}
              helperText=""
              error={values.errorNoticesEmail}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="address"
              name="address"
              type="text"
              label="Address"
              value={values.address}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange("address")}
              error={values.errorAddress}
              helperText=""
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="city"
              name="city"
              type="text"
              label="City"
              value={values.city}
              inputProps={{ maxLength: 32 }}
              onChange={handleChange("city")}
              error={values.errorCity}
              helperText=""
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="state"
              name="state"
              type="text"
              label="State / Province"
              value={values.state}
              inputProps={{ maxLength: 32 }}
              onChange={handleChange("state")}
              error={values.errorState}
              helperText=""
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="zip"
              name="zip"
              type="text"
              label="Zip"
              value={values.zip}
              inputProps={{ maxLength: 8 }}
              onChange={handleChange("zip")}
              error={values.errorZip}
              helperText=""
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            <TextField
              required
              id="country"
              name="country"
              type="text"
              label="Country"
              value={values.country}
              inputProps={{ maxLength: 32 }}
              onChange={handleChange("country")}
              error={values.errorCountry}
              helperText=""
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="form-group">
            {/* <TextField
              required
              id="primary_contact_phone"
              name="primary_contact_phone"
              type="text"
              label="Primary Contact Phone"
              value={values.primary_contact_phone}
              inputProps={{ maxLength: 10, minLength: 7 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/gi, "");
              }}
              onChange={handleChange("primary_contact_phone")}
              error={values.errorContactPhone}
              helperText="only numbers, 7 to 10 characters"
              variant="outlined"
              fullWidth
            /> */}
            <label htmlFor="primary_contact_phone">Primary Contact Phone</label>
            <PhoneInput
              required
              defaultCountry={"us"}
              inputProps={{
                name: "primary_contact_phone",
                id: "primary_contact_phone",
                maxLength: 20,
              }}
              value={values.primary_contact_phone}
              onChange={(value) => handleChangePhoneNumber({ target: { name: 'primary_contact_phone', value } })}
              inputStyle={{
                padding: "18px 8px",
              }}
              helperText="only numbers, 7 to 10 characters"
              label="Primary Contact Phone"
              error={values.errorContactPhone}
            />
            {values.errorContactPhone && values.errorContactPhone !== "" ? (
              <label className="text-danger">{values.errorContactPhone}</label>
            ) : (
              <label>only numbers, 7 to 10 characters</label>
            )}
          </div>

          <div className="form-group">
            {/* <TextField
              required
              id="your_phone"
              name="your_phone"
              type="text"
              label="Your Phone"
              value={values.your_phone}
              inputProps={{ maxLength: 10, minLength: 7 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/gi, "");
              }}
              onChange={handleChange("your_phone")}
              helperText="only numbers, 7 to 10 characters"
              error={values.errorYourPhone}
              variant="outlined"
              fullWidth
            /> */}
            <label htmlFor="your_phone">Your Phone</label>
            <PhoneInput
              required
              defaultCountry={"us"}
              inputProps={{
                name: "your_phone",
                id: "your_phone",
                maxLength: 20,
              }}
              value={values.your_phone}
              onChange={(value) => handleChangePhoneNumber({ target: { name: 'your_phone', value } })}
              inputStyle={{
                padding: "18px 8px",
              }}
              helperText="only numbers, 7 to 10 characters"
              label="Your Phone"
              error={values.errorYourPhone}
            />
             {values.errorYourPhone && values.errorYourPhone !== "" ? (
              <label className="text-danger">{values.errorYourPhone}</label>
            ) : (
              <label>only numbers, 7 to 10 characters</label>
            )}
          </div>

          <div className="form-group">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                required
                id="password"
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                defaultValue={values.password}
                inputProps={{ maxLength: 50, minLength: 8 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^0-9a-zA-Z@&*$%()+!]/gi,
                    ""
                  );
                }}
                onChange={handleChange("password")}
                helperText="Minimum 8 to 50 characters"
                error={values.errorPassword}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <PasswordStrengthBar
              password={values.password}
              minLength={8}
              onChangeScore={(score, feedback) => {
                this.setState({
                  suggestions: feedback.suggestions,
                });
                //console.log(score, feedback.suggestions);
              }}
            />
            {this.state.suggestions && this.state.suggestions.length > 0 ? (
              <>
                {this.state.suggestions.map((themes, index) => (
                  <figcaption
                    className="blockquote-footer"
                    key={`keys-${index}`}
                  >
                    {themes}
                  </figcaption>
                ))}
              </>
            ) : null}
          </div>

          <div className="form-group">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                required
                id="confirm_password"
                name="confirm_password"
                type={this.state.showConfirmPassword ? "text" : "password"}
                defaultValue={values.confirm_password}
                inputProps={{ maxLength: 50, minLength: 8 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^0-9a-zA-Z@&*$%()+!]/gi,
                    ""
                  );
                }}
                onChange={handleChange("confirm_password")}
                helperText="Minimum 8 to 50 characters"
                error={values.errorConfirmPassword}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {this.state.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
          </div>

          <div
            className="custom-control custom-control-lg custom-checkbox  mt-2 mb-5"
            style={{ paddingLeft: "0px" }}
          >
            <FormControlLabel
              style={{ marginBottom: "0px" }}
              control={
                <Checkbox
                  checked={values.agree_terms}
                  onChange={handleChangeCheckbox("agree_terms")}
                  name="agree_terms"
                  id="agree_terms"
                  color="primary"
                  type="checkbox"
                />
              }
            />
            <label
              className=""
              htmlFor="agree_terms"
              style={{ lineHeight: "2.5rem" }}
            >
              I agree to the{" "}
              <a href={values.terms_url} target="_blank">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a href={values.privacy_url} target="_blank">
                Privacy policy
              </a>
              .
            </label>
          </div>

          <div className="row g-gs">
            <div className="col-md-6">
              {!values.verifyDetailsButton ? (
                <button
                  type="button"
                  className="btn btn-lg btn-outline-light  btn-block"
                  disabled={this.state.disabled}
                  onClick={this.back}
                  style={{ marginRight: "10px" }}
                >
                  Back
                </button>
              ) : null}
            </div>
            <div className="col-md-6">
              {values.verifyDetailsButton ? (
                <button
                  className="btn btn-lg btn-primary btn-block"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Processing...</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg btn-primary btn-block"
                  onClick={this.submitForm}
                >
                  Continue
                  <em className="icon ni ni-done"></em>
                </button>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default GetUserdeteil;
