import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { HTML5_FMT } from "moment";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";
import { COMPANY_NAME } from "../../config/config";

export class VerifyEmail extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      verifyEmailButton: false,
    };
  }

  SubmitFirst = async () => {
    const { values, checkUsername } = this.props;
    console.log("this.state.email: ", values.selected_email);
    let firstResult = await checkUsername();
  };

  render() {
    const {
      values,
      handleChange,
      checkUsername,
      verifyEmailButton,
      addMenuHistory,
    } = this.props;

    return (
      <>
        <div className="nk-block-head mb-4">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Forgot your password?</h4>
            <div className="nk-block-des">
              Enter the username or an email registered with {COMPANY_NAME}.
            </div>
          </div>
        </div>
        <div className="form-group">
          <TextField
            required
            id="email"
            name="email"
            type="text"
            label="Email or Username"
            defaultValue={values.email}
            inputProps={{ maxLength: 100 }}
            onChange={handleChange("email")}
            helperText="Enter your email or username"
            variant="outlined"
            autoFocus
            fullWidth
          />
        </div>

        <hr />
        <div className="form-group text-right">
          {values.verifyEmailButton ? (
            <button
              className="btn btn-lg btn-primary btn-block"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Processing...</span>
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary btn-block"
              disabled={this.state.disabled}
              onClick={this.SubmitFirst}
            >
              Next
            </button>
          )}
        </div>
      </>
    );
  }
}

export default VerifyEmail;
