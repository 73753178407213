import React, { Component, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MuiThemeProvider,
} from "@material-ui/core";
import { HELP_SUPPORT, CONTACT_US_URL } from "../../config/config";

export class Success extends Component {
  first = () => {
    setTimeout(() => {
      window.location.reload();
      //this.props.firstStep();
    }, 500);
  };
  render() {
    return (
      <>
        <div className="nk-block-head justify-content-center  mb-4">
          <div className="nk-block-head-content text-center">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
            <h4 className="nk-block-title text-center  mt-4">
              Update Password Successful
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <p>
            Congratulations, you have successfully updated your password. You
            can now sign in using your new credentials.
            <br />
            <br />
            Should you need further assistance, do not hesitate to visit our{" "}
            <a href={HELP_SUPPORT} target="_blank">
              Help & Support
            </a>{" "}
            page, or reach out to us directly via our{" "}
            <a href={CONTACT_US_URL} target="_blank">
              Contact Us
            </a>{" "}
            page.
            <br />
          </p>
        </div>
        <div className="form-group d-flex justify-content-end mt-4">
          <button
            className="btn btn-lg btn-primary btn-block"
            onClick={this.first}
          >
            <em className="icon ni ni-first"></em>
            Go Back
          </button>
        </div>
      </>
    );
  }
}

export default Success;
