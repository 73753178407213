import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listSharelinks,
  getSharelinkTheme,
  getSharelinkServices,
  getSharelinkLogos,
  getSharelinkSettings,
  saveSharelinkDetail,
  saveSharelinkTheme,
  saveSharelinkServices,
  getSharelinkBundles,
  saveSharelinkBundles,
  saveSharelinkLogos,
  saveSharelinkContact,
  saveSharelinkSocial,
  saveSharelinkSEO,
  saveSharelinkAbout,
  regenerateSharelink,
  sendEmailSharelink,
  getSharelinkStats,
  getDomains,
  connectSharelinkSubmit,
  getAssignedDomain,
  getContactLogs,
} from "./../../../config/shortlink_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import DataTableSmall from "../../../components/Tables/DataTableSmall";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { tableLoaderBlockImage } from "../../../components/ContentLoaders/table_loader_imageblock";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import { tableLoaderLine } from "../../../components/ContentLoaders/table_loader_line";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Sharelink extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataContactLogs: [],
      serviceData: [],
      selected_bundle: [],
      bundlesData: [],
      defaultServices: [],
      errorMessage: "",
      uErrorMessage: "",
      uSuccessMessage: "",
      linkSuccessMessage: "",
      linkErrorMessage: "",
      getThemeErrorMessage: "",
      getServiceErrorMessage: "",
      getBundleErrorMessage: "",
      getLogoErrorMessage: "",
      getAboutErrorMessage: "",
      getContactErrorMessage: "",
      getSocialErrorMessage: "",
      getSeoErrorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoaderListing: true,
      tableLoaderBlockImage: true,
      tableLoaderBlockService: true,
      tableLoaderBlockBundle: true,
      tableLoaderBlockLogo: true,
      tableLoaderBlockAbout: true,
      tableLoaderBlockSocial: true,
      tableLoaderBlockContact: true,
      tableLoaderBlockSEO: true,
      tableLoaderLine: true,
      eErrorMessage: "",
      eSuccessMessage: "",
      copied: false,
      cpied_value: "",
      send_email: "",
      title: "",
      status: "",
      landing_page: "",
      about_me_settings: false,
      contact_settings: false,
      social_settings: false,
      theme: "",
      meta_description: "",
      meta_title: "",
      sharelink_id: "",
      complete_link: "",
      themesList: [],
      selected_service: [],
      select_logo: false,
      select_avatar: false,
      selected_logo: "",
      selected_avatar: "",
      avatarData: [],
      about_me_short: "",
      about_me: "",
      instagram_link: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      settingData: [],
      logoData: [],
      reg_button: false,
      reg_email_button: false,
      sharelink2_id: "",
      signup_link: "",
      show_regenerate: "",
      custom_link: "",
      email_to: "",
      contact_address: auth.getAddress(),
      contact_email: auth.getEmail(),
      contact_phone: auth.getPhone(),
      stat_hits: 0,
      last_visit: "",
      statErrorMessage: "",
      available_domains: [],
      btn_loader: false,
      assigned_domain: "",
      sharelink_limit: 0,
      sharelink_added: 0,
      columnServices: [
        { name: "Title" },
        { name: "Date Added" },
        {
          name: "About Me",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Contact Settings",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Social Settings",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultSettings(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Landing Page",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.landinPage(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Agent Link",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => this.agentLink(value, tableMeta)}
                            title="Agent Share Link"
                            disabled={
                              this.props.is_msa_signed === "No" ||
                              this.props.defaultSettings === "missing"
                                ? true
                                : false
                            }
                            className=" btn btn-icon  btn-outline-light"
                          >
                            <em className="icon ni ni-link-h"></em>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            style={{ minWidth: "220px" }}
                          >
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Sharelink Details</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.sharelinkStats(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Sharelink Stats"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-reports-alt"></em>
                                  <span>Sharelink Stats</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.connectDomain(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Sharelink Stats"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-link"></em>
                                  <span>Sharelink Domain</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.contactLogs(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Contact Logs"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-contact"></em>
                                  <span>Contact Logs</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnContactLogs: [
        { name: "Date Added" },
        { name: "Name" },
        { name: "Email" },

        { name: "Phone" },
        { name: "Subject" },
        { name: "Message" },
      ],
    };
  }

  showDetails = async (value, tableMeta) => {
    let services_data = this.state.tableData;
    for (let i = 0; i < services_data.length; i++) {
      if (services_data[i][8] === value) {
        let about_setting = false;
        if (services_data[i][2] === "Yes") {
          about_setting = true;
        }
        let cont_setting = false;
        if (services_data[i][3] === "Yes") {
          cont_setting = true;
        }
        let sm_setting = false;
        if (services_data[i][4] === "Yes") {
          sm_setting = true;
        }
        this.setState({
          sharelink_id: services_data[i][8],
          title: services_data[i][0],
          status: services_data[i][5],
          about_me_settings: about_setting,
          contact_settings: cont_setting,
          social_settings: sm_setting,
          complete_link: services_data[i][7],
          landing_page: services_data[i][6],
        });
      }
    }

    //$('a[href="#tab-1"]').click();
    $("#detail_tabs ul li a").removeClass("active"); // remove from all buttons
    $(".tab-content .tab-pane").removeClass("active"); // remove from all tabs

    $("#detail_tabs ul li a[href='#tab-1']").addClass("active"); // add to the targeted button
    $(".tab-content #tab-1").addClass("active"); // add to the targedted tabs
    //console.log("title: ", this.state.title);
    window.$("#modalDetails").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  agentLink = (value, tableMeta) => {
    let agent_link = tableMeta.rowData[7];
    let sharelink_id = tableMeta.rowData[8];
    this.getAssignedDomain(sharelink_id);
    this.setState({
      copied: false,
      signup_link: agent_link,
      sharelink2_id: sharelink_id,
    });
    window.$("#modalSignupLink").modal("show");
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  landinPage = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[6];

    if (is_default === "No") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultSettings = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-warning">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  getAssignedDomain = async (sharelink_id) => {
    const servicesResponce = await getAssignedDomain(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getAssignedDomain: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        tableLoaderLine: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assigned_domain: "https://" + servicesResponce.data.domain,
        tableLoaderLine: false,
      });
    } else {
      this.setState({
        tableLoaderLine: false,
      });
    }
  };

  sharelinkStats = async (value, tableMeta) => {
    let sharelink_id = tableMeta.rowData[8];
    const servicesResponce = await getSharelinkStats(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    //console.log("sharelinkStats: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        statErrorMessage: servicesResponce.data.data,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        statErrorMessage: "",

        stat_hits: servicesResponce.data.hits,
        last_visit: servicesResponce.data.last_visit,
      });
      //modalStats
      window.$("#modalStats").modal("show");
    } else {
      this.setState({
        statErrorMessage: "There is some error while getting the stats.",
      });
      // window.location.replace("/error");
    }
  };

  contactLogs = async (value, tableMeta) => {
    let sharelink_id = tableMeta.rowData[8];
    const servicesResponce = await getContactLogs(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    this.setState({
      tableDataContactLogs: [],
    });
    //console.log("contactLogs: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "errors"
    ) {
      this.setState({
        tableDataContactLogs: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableDataContactLogs: servicesResponce.data.data,
        tableLoaderListing: false,
      });
      //modalStats
      window.$("#modalContactLogs").modal("show");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableDataContactLogs: [],
      });
      //modalStats
      window.$("#modalContactLogs").modal("show");
    } else {
      this.setState({
        tableDataContactLogs: [],
      });
      // window.location.replace("/error");
    }
  };

  send_email = async () => {
    const { sharelink2_id, email_to } = this.state;

    this.setState({
      reg_email_button: true,
      eErrorMessage: "",
      eSuccessMessage: "",
    });

    if (email_to === "") {
      this.setState({
        eErrorMessage: "Please enter the email address.",
        eSuccessMessage: "",
        reg_email_button: false,
      });
    } else {
      const servicesResponce = await sendEmailSharelink(
        auth.getAccount(),
        auth.getToken(),
        sharelink2_id,
        email_to,
        this.state.signup_link
      );
      //console.log("Regenerate.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "errors"
      ) {
        this.setState({
          eErrorMessage: servicesResponce.data.data,
          eSuccessMessage: "",
          reg_email_button: false,
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          eErrorMessage: "",
          eSuccessMessage: "Sharelink sent successfully.",
          reg_email_button: false,
          email_to: "",
        });
      } else {
        this.setState({
          eErrorMessage: "There is some error while emailing the sharelink.",
          eSuccessMessage: "",
          reg_email_button: false,
        });
        // window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ eSuccessMessage: "", eErrorMessage: "" });
    }, 5000);
  };

  async componentDidMount() {
    const servicesResponce = await listSharelinks(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listSharelinks: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoaderListing: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoaderListing: false,
        sharelink_added: servicesResponce.data.data.length,
        sharelink_limit: servicesResponce.data.sharelink_limit,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoaderListing: false,
      });
    }
  }

  groupBy = (arr, property) => {
    let intArray = arr;
    let evenArray = [];
    intArray.map((x) => {
      if (x.images_type === property) {
        evenArray.push(x);
      }
    });

    return evenArray;
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      about_me: content,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  open_add_form = () => {
    window.$("#modalAddSharelink").modal("show");
  };

  show_email = () => {
    this.setState({
      send_email: "1",
      show_regenerate: "",
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  setTheme = (id) => {
    //console.log("Theme: ", id);
    this.setState({
      theme: id,
    });
  };
  setThemeClass = (id) => {
    //console.log("setThemeClass: ", this.state.theme + " " + id);
    if (this.state.theme === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setThemeClassMain = (id) => {
    if (this.state.theme === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setService = (id) => {
    //console.log("Service: ", id);
    if (this.state.selected_service.includes(id)) {
      var checked = this.state.selected_service;
      var values = checked.indexOf(id);
      checked.splice(values, 1);
      this.setState({ selected_service: checked });
    } else {
      this.setState({
        selected_service: [...this.state.selected_service, id],
      });
    }
  };

  setServiceClass = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "btn btn-primary active3";
    } else {
      return "btn btn-dim btn-white btn-outline-primary";
    }
  };

  setServiceClassMain = (id) => {
    if (this.state.selected_service.includes(id)) {
      return "card card-bordered sp-plan active2";
    } else {
      return "card card-bordered sp-plan";
    }
    /*if (this.state.selected_service.includes(id)) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }*/
  };

  setBundleClassMainNew = (id) => {
    if (this.state.selected_bundle.includes(id)) {
      return "card card-bordered sp-plan active2";
    } else {
      return "card card-bordered sp-plan";
    }
  };

  setBundleClassNew = (id) => {
    if (this.state.selected_bundle.includes(id)) {
      return "btn btn-primary active3";
    } else {
      return "btn btn-dim btn-white btn-outline-primary";
    }
  };

  setBundles = (id) => {
    //console.log("Service: ", id);
    if (this.state.selected_bundle.includes(id)) {
      var checked = this.state.selected_bundle;
      var values = checked.indexOf(id);
      checked.splice(values, 1);
      this.setState({ selected_bundle: checked });
    } else {
      this.setState({
        selected_bundle: [...this.state.selected_bundle, id],
      });
    }
  };

  setLogo = (id) => {
    //console.log("Logo: ", id);
    this.setState({
      selected_logo: id,
    });
  };

  setLogoClass = (id) => {
    if (this.state.selected_logo === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setLogoClassMain = (id) => {
    if (this.state.selected_logo === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  setAvatar = (id) => {
    // console.log("Avatar: ", id);
    this.setState({
      selected_avatar: id,
    });
  };

  setAvatarClass = (id) => {
    if (this.state.selected_avatar === id) {
      return "read active";
    } else {
      return "read";
    }
  };

  setAvatarClassMain = (id) => {
    if (this.state.selected_avatar === id) {
      return "pricingTable active2";
    } else {
      return "pricingTable";
    }
  };

  getSharelinkTheme = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockImage: true,
      getThemeErrorMessage: "",
    });
    const servicesResponce = await getSharelinkTheme(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      theme
    );
    //console.log("sharelink_id: ", sharelink_id);
    console.log("getSharelinkTheme: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "Theme not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        themesList: servicesResponce.data.data,
        theme: servicesResponce.data.theme,
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        themesList: [],
        theme: "",
        tableLoaderBlockImage: false,
        getThemeErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockImage: false,
        getThemeErrorMessage: "There is some error while getting themes.",
      });
    }
  };

  getSharelinkServices = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockService: true,
      getServiceErrorMessage: "",
    });
    const servicesResponce = await getSharelinkServices(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    //console.log("getSharelinkServices: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockService: false,
        getServiceErrorMessage: "Services not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        selected_service: servicesResponce.data.assigned_services,
        tableLoaderBlockService: false,
        getServiceErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        serviceData: [],
        selected_service: "",
        tableLoaderBlockService: false,
        getServiceErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockService: false,
        getServiceErrorMessage: "There is some error while getting services.",
      });
    }
  };

  getSharelinkBundles = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockBundle: true,
      getBundleErrorMessage: "",
    });
    const servicesResponce = await getSharelinkBundles(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkBundles: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "Bundles not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bundlesData: servicesResponce.data.data,
        selected_bundle: servicesResponce.data.assigned_services,
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockBundle: false,
        getBundleErrorMessage: "There is some error while getting the bundles.",
      });
    }
  };

  getSharelinkLogos = async () => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      tableLoaderBlockLogo: true,
      getLogoErrorMessage: "",
    });
    const servicesResponce = await getSharelinkLogos(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkLogos: ", servicesResponce.data);
    let logo_array = [];
    let avatar_array = [];
    if (servicesResponce.data.data) {
      logo_array = this.groupBy(servicesResponce.data.data, "logo");
      avatar_array = this.groupBy(servicesResponce.data.data, "image");
    }

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "Logos not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        logoData: logo_array,
        avatarData: avatar_array,
        selected_logo: servicesResponce.data.logo,
        selected_avatar: servicesResponce.data.avatar,
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage:
          "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
      });
    } else {
      this.setState({
        tableLoaderBlockLogo: false,
        getLogoErrorMessage: "There is some error while getting the logos.",
      });
    }
  };

  getSharelinkSettings = async (type) => {
    const { theme, sharelink_id } = this.state;
    this.setState({
      getAboutErrorMessage: "",
      getSeoErrorMessage: "",
      getContactErrorMessage: "",
      getSocialErrorMessage: "",
      about_me_short: "",
      about_me: "",
      instagram_link: "",
      facebook_link: "",
      twitter_link: "",
      linkedin_link: "",
      youtube_link: "",
      contact_address: "",
      contact_email: "",
      contact_phone: "",
      meta_description: "",
      meta_title: "",
    });
    //if (this.state.about_me === "") {
    if (type === "about") {
      this.setState({
        tableLoaderBlockAbout: true,
        getAboutErrorMessage: "",
      });
    }
    if (type === "social") {
      this.setState({
        tableLoaderBlockSocial: true,
        getSocialErrorMessage: "",
      });
    }
    if (type === "contact") {
      this.setState({
        tableLoaderBlockContact: true,
        getContactErrorMessage: "",
      });
    }
    if (type === "seo") {
      this.setState({
        tableLoaderBlockSEO: true,
        getSeoErrorMessage: "",
      });
    }

    const servicesResponce = await getSharelinkSettings(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id
    );
    console.log("getSharelinkSettings sharelink_id: ", sharelink_id);
    console.log("getSharelinkSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      if (type === "about") {
        this.setState({
          getSocialErrorMessage: "",
          getContactErrorMessage: "",
          getSeoErrorMessage: "",
          getAboutErrorMessage: "About me content not found.",
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage: "Social media links not found.",
          getAboutErrorMessage: "",
          getContactErrorMessage: "",
          getSeoErrorMessage: "",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage: "Contact details not found.",
          getAboutErrorMessage: "",
          getSocialErrorMessage: "",
          getSeoErrorMessage: "",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage: "SEO details not found.",
          getAboutErrorMessage: "",
          getSocialErrorMessage: "",
          getContactErrorMessage: "",
        });
      }
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        about_me_short:
          servicesResponce.data.sharelink_settings[0]["about_me_short"],
        about_me: servicesResponce.data.sharelink_settings[0]["about_me"],
        instagram_link:
          servicesResponce.data.sharelink_settings[0]["instagram_link"],
        facebook_link:
          servicesResponce.data.sharelink_settings[0]["facebook_link"],
        twitter_link:
          servicesResponce.data.sharelink_settings[0]["twitter_link"],
        linkedin_link:
          servicesResponce.data.sharelink_settings[0]["linkedin_link"],
        youtube_link:
          servicesResponce.data.sharelink_settings[0]["youtube_link"],
        settingData: servicesResponce.data.data,
        contact_address:
          servicesResponce.data.sharelink_settings[0]["contact_address"],
        contact_email:
          servicesResponce.data.sharelink_settings[0]["contact_email"],
        contact_phone:
          servicesResponce.data.sharelink_settings[0]["contact_phone"],
        meta_description: servicesResponce.data.meta_description,
        meta_title: servicesResponce.data.meta_title,
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "sharelink_not_found"
    ) {
      if (type === "about") {
        this.setState({
          getAboutErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage:
            "Share link does not exists or Landing page setting is set to No. Please click Sharelink Detail tab, select Landing Page to Yes and Save Details.",
        });
      }
    } else {
      if (type === "about") {
        this.setState({
          getAboutErrorMessage:
            "There is some error while getting about me content.",
        });
      }
      if (type === "social") {
        this.setState({
          getSocialErrorMessage:
            "There is some error while getting social media links.",
        });
      }
      if (type === "contact") {
        this.setState({
          getContactErrorMessage:
            "There is some error while getting contact details.",
        });
      }
      if (type === "seo") {
        this.setState({
          getSeoErrorMessage: "There is some error while getting SEO data.",
        });
      }
    }
    if (type === "about") {
      this.setState({
        tableLoaderBlockAbout: false,
      });
    }
    if (type === "social") {
      this.setState({
        tableLoaderBlockSocial: false,
      });
    }
    if (type === "contact") {
      this.setState({
        tableLoaderBlockContact: false,
      });
    }
    if (type === "seo") {
      this.setState({
        tableLoaderBlockSEO: false,
      });
    }
    /* } else {
      this.setState({
        tableLoaderBlockContact: false,
        tableLoaderBlockAbout: false,
        tableLoaderBlockSocial: false,
        tableLoaderBlockSEO: false,
      });
    }*/
  };

  saveSharelinkDetail = async () => {
    const { title, status, sharelink_id } = this.state;

    const saveResponce = await saveSharelinkDetail(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      title,
      status
    );
    //console.log("saveSharelinkDetail: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Share link detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the sharelink detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkTheme = async () => {
    const { theme, sharelink_id } = this.state;
    const saveResponce = await saveSharelinkTheme(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      theme
    );
    //console.log("saveSharelinkTheme: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Theme updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the theme.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkServices = async () => {
    const { sharelink_id } = this.state;
    //console.log("this.state.selected_service: ", this.state.selected_service);
    const saveResponce = await saveSharelinkServices(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      this.state.selected_service
    );
    //console.log("saveSharelinkServices: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not exists.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Services updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the services.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkBundles = async () => {
    const { sharelink_id } = this.state;
    //console.log("this.state.selected_service: ", this.state.selected_service);
    const saveResponce = await saveSharelinkBundles(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      this.state.selected_bundle
    );
    console.log("saveSharelinkBundles: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not exists.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Bundles updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the bundles.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkLogos = async () => {
    const { sharelink_id, selected_logo, selected_avatar } = this.state;

    const saveResponce = await saveSharelinkLogos(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      selected_logo,
      selected_avatar
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Images updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the images.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkAbout = async () => {
    const { sharelink_id, about_me_short, about_me } = this.state;
    this.setState({
      uErrorMessage: "",
      uSuccessMessage: "",
      disabled: false,
    });
    const saveResponce = await saveSharelinkAbout(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      about_me_short,
      about_me
    );
    //console.log("saveSharelinkAbout: ", saveResponce.data);

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "About me detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the about me detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkContact = async () => {
    const { sharelink_id, contact_address, contact_email, contact_phone } =
      this.state;

    const saveResponce = await saveSharelinkContact(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      contact_address,
      contact_email,
      contact_phone
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Contact detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the contact detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkSocial = async () => {
    const {
      sharelink_id,
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
    } = this.state;

    const saveResponce = await saveSharelinkSocial(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "Social media links updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage:
          "There is some error while updating the social media links.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  saveSharelinkSEO = async () => {
    const { sharelink_id, meta_description, meta_title } = this.state;

    const saveResponce = await saveSharelinkSEO(
      auth.getAccount(),
      auth.getToken(),
      sharelink_id,
      meta_description,
      meta_title
    );

    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "not_found"
    ) {
      // window.location.replace("/error");
      this.setState({
        uErrorMessage: "Sharelink does not found.",
        uSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        uErrorMessage: "",
        uSuccessMessage: "SEO detail updated successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        uErrorMessage: "There is some error while updating the SEO detail.",
        uSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ uSuccessMessage: "", uErrorMessage: "" });
    }, 5000);
  };

  show_regenerate = () => {
    this.setState({
      show_regenerate: "1",
      send_email: "",
    });
  };

  regenerate_link = async () => {
    const { sharelink2_id, custom_link } = this.state;

    this.setState({
      reg_button: true,
      linkErrorMessage: "",
      linkSuccessMessage: "",
    });

    if (custom_link === "") {
      this.setState({
        linkErrorMessage: "Please enter the custom link ID.",
        linkSuccessMessage: "",
        reg_button: false,
      });
    } else {
      const servicesResponce = await regenerateSharelink(
        auth.getAccount(),
        auth.getToken(),
        sharelink2_id,
        custom_link
      );
      //console.log("Regenerate.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "errors"
      ) {
        this.setState({
          linkErrorMessage: servicesResponce.data.data,
          linkSuccessMessage: "",
          reg_button: false,
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          signup_link: servicesResponce.data.data,
          linkErrorMessage: "",
          linkSuccessMessage: "Sharelink generated successfully.",
          reg_button: false,
          custom_link: "",
        });
      } else {
        this.setState({
          linkErrorMessage: "There is some error while updating the sharelink.",
          linkSuccessMessage: "",
          reg_button: false,
        });
        // window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ linkSuccessMessage: "", linkErrorMessage: "" });
    }, 5000);
  };

  connectDomain = async (value, tableMeta) => {
    //modalConfirm

    this.setState({
      confirm_status: "Are you sure you want to connect this domain. ",
      sharelink_id: tableMeta.rowData[8],
    });
    const sharelinkResponce = await getDomains(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getDomains.data: ", sharelinkResponce.data);

    if (
      sharelinkResponce.data.status === 403 ||
      sharelinkResponce.data.errors === "authentication missing" ||
      sharelinkResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      sharelinkResponce.data.status === 404 &&
      sharelinkResponce.data.message === "no_record"
    ) {
      this.setState({
        errorMessage: "Domains are not available.",
        successMessage: "",
      });
    } else if (
      sharelinkResponce.data.status === 200 &&
      sharelinkResponce.data.message === "success"
    ) {
      this.setState({
        available_domains: sharelinkResponce.data.data,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting available domains.",
        successMessage: "",
      });
    }

    window.$("#modalConfirm").modal("show");
  };

  connectDomainSubmit = async () => {
    let { domain_id, sharelink_id } = this.state;
    if (domain_id === "") {
      this.setState({
        errorMessage: "Please select a domain to connect.",
        successMessage: "",
      });
    } else {
      const disableResponce = await connectSharelinkSubmit(
        auth.getAccount(),
        auth.getToken(),
        domain_id,
        sharelink_id
      );
      console.log("connectSharelinkSubmit.data: ", disableResponce.data);
      this.setState({
        btn_loader: true,
      });
      if (
        disableResponce.data.status === 403 ||
        disableResponce.data.errors === "authentication missing" ||
        disableResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message === "config_error"
      ) {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while adding the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "verify_error"
      ) {
        this.setState({
          errorMessage: "DNS records of given domain are not verified.",
          successMessage: "",
        });
      } else if (
        disableResponce.data.status === 404 &&
        disableResponce.data.message !== "remove_config_error"
      ) {
        this.setState({
          errorMessage:
            "There is some error while removing the domain configuration.",
          successMessage: "",
          btn_loader: false,
        });
      } else if (
        disableResponce.data.status === 200 &&
        disableResponce.data.message === "success"
      ) {
        this.setState({
          successMessage:
            "Your request has been received. It will be processed in 5 to 10 minutes.",
          errorMessage: "",
          btn_loader: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while connecting the domain.",
          successMessage: "",
          btn_loader: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        btn_loader: false,
      });
    }, 4000);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Agent Sharelinks"
                        icon="icon ni ni-share"
                      />
                    </h3>
                    <div className="nk-block-des">
                      <p className="lead" style={{ paddingRight: "100px" }}>
                        {this.state.sharelink_limit > 0 ? (
                          <>
                            You are allowed to create up to{" "}
                            <b>{this.state.sharelink_limit}</b> share links.
                            With our platform, you have the freedom to generate
                            and share up to 5 unique links. This limit ensures
                            that you have a reasonable number of shareable links
                            while maintaining control and manageability. Should
                            you require more share links, please contact our
                            support team who will be happy to assist you with
                            any additional requirements.
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          {this.state.sharelink_limit > 0 &&
                          this.state.sharelink_added <
                            this.state.sharelink_limit ? (
                            <li className="nk-block-tools-opt">
                              <a
                                onClick={() =>
                                  this.addMenuHistory(
                                    "sharelinks/add_sharelink"
                                  )
                                }
                                style={{
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Sharelink</span>
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoaderListing === true ? (
                        tableLoaderListing()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Sharelinks"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* Sharelink MODAL START */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalSignupLink"
              >
                <div className="modal-dialog  modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-link-alt"></em> Agent
                          Sharelink
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {this.state.tableLoaderLine === true ? (
                            tableLoaderLine()
                          ) : (
                            <>
                              {this.state.assigned_domain !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{
                                    marginBottom: "15px",
                                  }}
                                >
                                  <div className="alert alert-info alert-icon">
                                    <em className="icon ni ni-link-alt"></em>{" "}
                                    <strong>Assigned Domain: </strong>{" "}
                                    {this.state.assigned_domain}{" "}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                          <div className="example-alert">
                            <div className="alert alert-light alert-icon">
                              <em className="icon ni ni-alert-circle"></em>{" "}
                              <strong>NOTE: </strong> Share this link with your
                              clients to showcase your services and assist them
                              with signing up.{" "}
                            </div>
                          </div>
                          <div
                            className="caption-text"
                            style={{ marginBottom: "15px", marginTop: "20px" }}
                          >
                            <div className="flex-container">
                              <div
                                className="col-lg-8 col-xxl-8 col-md-8"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div className="alert alert-light column">
                                    {this.state.signup_link}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-lg-4 col-xxl-4 col-md-4"
                                style={{ paddingRight: "0px" }}
                              >
                                {this.state.signup_link !== "" ? (
                                  <div
                                    className="example-alert "
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div
                                      className="alert alert-light "
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      <img
                                        src={`https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${this.state.signup_link}`}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <button
                              className="btn btn-primary"
                              style={{ marginRight: "15px" }}
                              onClick={() => this.show_regenerate()}
                            >
                              <em className="icon ni ni-swap"></em> Regenerate
                            </button>

                            <CopyToClipboard
                              text={this.state.signup_link}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <button className="btn btn-success">
                                <em className="icon ni ni-copy"></em> Copy to
                                clipboard{" "}
                                {this.state.copied ? (
                                  <span style={{ color: "white" }}>
                                    <em className="icon ni ni-done"></em>
                                  </span>
                                ) : null}
                              </button>
                            </CopyToClipboard>
                            <button
                              className="btn btn-warning"
                              style={{ marginLeft: "15px" }}
                              onClick={() => this.show_email()}
                            >
                              <em className="icon ni ni-emails"></em> Email Link
                            </button>
                          </div>
                          {this.state.show_regenerate === "1" ? (
                            <div
                              className="row g-4 mt-15"
                              style={{ marginTop: "35px", clear: "both" }}
                            >
                              {this.state.linkErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.linkErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.linkSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.linkSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-8">
                                <MutextField
                                  required
                                  id="custom_link"
                                  name="custom_link"
                                  type="text"
                                  label="Custom Link ID"
                                  defaultValue={this.state.custom_link}
                                  inputProps={{ maxLength: 50 }}
                                  onChange={this.handleChange}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^a-z0-9]/gi,
                                      ""
                                    );
                                  }}
                                  helperText="Without spaces and special characters."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-4">
                                {this.state.reg_button === false ? (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => this.regenerate_link()}
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-swap"></em> Submit
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Submit
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : null}
                          {this.state.send_email === "1" ? (
                            <div
                              className="row g-4 mt-15"
                              style={{ marginTop: "25px" }}
                            >
                              {this.state.eErrorMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.eErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.eSuccessMessage !== "" ? (
                                <div
                                  className="example-alert col-md-12"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.eSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-8">
                                <MutextField
                                  required
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  label="Enter Email Address"
                                  defaultValue={this.state.email_to}
                                  inputProps={{ maxLength: 100 }}
                                  onChange={this.handleChange}
                                  helperText="Enter your Email Address here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              <div className="col-md-4">
                                {this.state.reg_email_button === false ? (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => this.send_email()}
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-emails"></em> Send
                                    Email Link
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-xl btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Send
                                    Email Link
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sharelink MODAL End */}
              {/* STATS MODAL */}
              <div className="modal fade zoom" tabIndex="-1" id="modalStats">
                <div
                  className="modal-dialog modal-dialog-top  modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-share"></em> Sharelink Stats
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.statErrorMessage !== "" ? (
                        <div
                          className="example-alert col-md-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.statErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="card-inner">
                        <div className="row text-center">
                          <div className="col-6">
                            <div className="profile-stats">
                              <span className="amount">
                                {this.state.stat_hits}
                              </span>
                              <span className="sub-text">Total Hits</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="profile-stats">
                              <span className="amount">
                                {this.state.last_visit}
                              </span>
                              <span className="sub-text">Last Visit</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* STATS MODAL */}
              {/* DETAIL MODAL START */}
              <div className="modal fade zoom" tabIndex="-1" id="modalDetails">
                <div
                  className="modal-dialog  modal-xl modal-dialog-top new_modal_width"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="col-md-8 col-lg-8"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div
                          className="modal-title"
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                          }}
                        >
                          <strong>
                            <em className="icon ni ni-link-alt"></em> Sharelink
                            Detail
                          </strong>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <a
                          href={this.state.complete_link}
                          style={{ zIndex: "100" }}
                          target="_blank"
                          className="btn btn-outline-primary"
                        >
                          Preview Sharelink
                        </a>
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                      </div>
                    </div>

                    <div
                      className="modal-body modal-body-lg"
                      style={{ padding: ".2rem .2rem" }}
                    >
                      <div className="nk-modal" id="sharelinkdetail">
                        <div className="nk-modal-text">
                          <section className="tabs-section text-white">
                            <div className="container">
                              {this.state.uErrorMessage !== "" ? (
                                <div
                                  className="col-md-12 col-lg-12"
                                  style={{
                                    marginBottom: "30px",
                                    clear: "both",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h3 style={{ color: "#526484" }}>
                                          Error
                                        </h3>
                                        <p>{this.state.uErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.uSuccessMessage !== "" ? (
                                <div
                                  className="col-md-12 col-lg-12"
                                  style={{
                                    marginBottom: "30px",
                                    clear: "both",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h3 style={{ color: "#526484" }}>
                                          Success
                                        </h3>
                                        <p>{this.state.uSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="row">
                                <div
                                  className="col-sm-5 col-lg-3"
                                  id="detail_tabs"
                                >
                                  <ul className="nav nav-tabs mb-3">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active show"
                                        data-toggle="tab"
                                        href="#tab-1"
                                      >
                                        <em className="icon ni ni-share"></em>
                                        Sharelink Detail
                                      </a>
                                    </li>
                                    {this.state.landing_page === "Yes" ? (
                                      <>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-2"
                                            onClick={() =>
                                              this.getSharelinkTheme()
                                            }
                                          >
                                            <em className="icon ni ni-dashboard"></em>{" "}
                                            Theme
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-3"
                                            onClick={() =>
                                              this.getSharelinkServices()
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            Services
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-33"
                                            onClick={() =>
                                              this.getSharelinkBundles()
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            Bundles
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-8"
                                            onClick={() =>
                                              this.getSharelinkLogos()
                                            }
                                          >
                                            <em className="icon ni ni-camera"></em>{" "}
                                            Image Library
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-4"
                                            onClick={() =>
                                              this.getSharelinkSettings("about")
                                            }
                                          >
                                            <em className="icon ni ni-align-justify"></em>{" "}
                                            About Me
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-5"
                                            onClick={() =>
                                              this.getSharelinkSettings(
                                                "social"
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-link-group"></em>
                                            Social Media Links
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-6"
                                            onClick={() =>
                                              this.getSharelinkSettings(
                                                "contact"
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-contact"></em>
                                            Contact Details
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#tab-7"
                                            onClick={() =>
                                              this.getSharelinkSettings("seo")
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>{" "}
                                            SEO Section
                                          </a>
                                        </li>
                                      </>
                                    ) : null}
                                  </ul>
                                </div>
                                <div className="col-sm-7 col-lg-9">
                                  <div className="tab-content">
                                    <div
                                      className="tab-pane active show"
                                      id="tab-1"
                                    >
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <div
                                            className="col-md-8 col-lg-8"
                                            style={{
                                              paddingLeft: "0px",
                                              float: "left",
                                            }}
                                          >
                                            <h3>Initial Detail</h3>
                                          </div>

                                          <div
                                            className="col-md-4 col-lg-4"
                                            style={{
                                              paddingLeft: "0px",
                                              float: "right",
                                              textAlign: "right",
                                            }}
                                          >
                                            {/*<a
                                              href={this.state.complete_link}
                                              style={{ zIndex: "100" }}
                                              target="_blank"
                                              className="btn btn-outline-success"
                                            >
                                              Preview
                                          </a>*/}
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              required
                                              id="title"
                                              name="title"
                                              type="text"
                                              label="Sharelink Title"
                                              inputProps={{ maxLength: 100 }}
                                              value={this.state.title}
                                              onChange={this.handleChange}
                                              helperText="Enter the title here."
                                              onInput={(e) => {
                                                e.target.value =
                                                  e.target.value.replace(
                                                    /[^a-z0-9 ]/gi,
                                                    ""
                                                  );
                                              }}
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              id="status"
                                              name="status"
                                              select
                                              label="Status"
                                              value={this.state.status}
                                              onChange={this.handleChange}
                                              SelectProps={{
                                                native: true,
                                              }}
                                              helperText="e.g Active or Inactive"
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="status1" value="1">
                                                Active
                                              </option>
                                              <option key="status0" value="0">
                                                Inactive
                                              </option>
                                            </MutextField>
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            <MutextField
                                              id="landing_page"
                                              name="landing_page"
                                              select
                                              label="Landing Page"
                                              value={this.state.landing_page}
                                              onChange={this.handleChange}
                                              SelectProps={{
                                                native: true,
                                              }}
                                              helperText="e.g Yes will display the landing page where client can select service and register. No will not display any landing page and redirect directly to registration page."
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option
                                                key="landing_page1"
                                                value="Yes"
                                              >
                                                Yes
                                              </option>
                                              <option
                                                key="landing_page0"
                                                value="No"
                                              >
                                                No
                                              </option>
                                            </MutextField>
                                          </div>

                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{
                                              paddingLeft: "0px",
                                              textAlign: "right",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-lg btn-primary"
                                              onClick={this.saveSharelinkDetail}
                                              disabled={this.state.disabled}
                                            >
                                              <em className="icon ni ni-note-add"></em>
                                              {"  "}Save Detail
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-2">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <h3>Update Theme</h3>
                                          <div
                                            className="col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            {this.state.getThemeErrorMessage !==
                                            "" ? (
                                              <div
                                                className="example-alert"
                                                style={{
                                                  marginBottom: "15px",
                                                  color: "#526484",
                                                }}
                                              >
                                                <div className="alert alert-pro alert-danger">
                                                  <div className="alert-text">
                                                    <h4
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      Error
                                                    </h4>
                                                    <p
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      {
                                                        this.state
                                                          .getThemeErrorMessage
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state
                                              .tableLoaderBlockImage ===
                                            true ? (
                                              tableLoaderBlockImage()
                                            ) : (
                                              <>
                                                {this.state.themesList &&
                                                this.state.themesList.length >
                                                  0 ? (
                                                  <div
                                                    className="card card-bordered"
                                                    id="sharelink_div"
                                                  >
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        {this.state.themesList.map(
                                                          (themes) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-sm-6"
                                                              key={`theme_key${themes.id}`}
                                                            >
                                                              <div
                                                                className={this.setThemeClassMain(
                                                                  themes.id
                                                                )}
                                                              >
                                                                <div className="pricingTable-header">
                                                                  <img
                                                                    src={`../../assets/images/${themes.theme_image}`}
                                                                  />
                                                                </div>

                                                                <a
                                                                  onClick={() =>
                                                                    this.setTheme(
                                                                      themes.id
                                                                    )
                                                                  }
                                                                  className={this.setThemeClass(
                                                                    themes.id
                                                                  )}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    fontSize:
                                                                      "12px",
                                                                    paddingLeft:
                                                                      "25px",
                                                                    paddingRight:
                                                                      "25px",
                                                                  }}
                                                                >
                                                                  {this.state
                                                                    .theme ===
                                                                  themes.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  {"  "}
                                                                  Choose Theme
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                          <div
                                            className="form-group col-md-12 col-lg-12"
                                            style={{
                                              marginTop: "20px",
                                              paddingLeft: "0px",
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.state.themesList &&
                                            this.state.themesList.length > 0 ? (
                                              <button
                                                type="button"
                                                className="btn btn-lg btn-primary"
                                                onClick={
                                                  this.saveSharelinkTheme
                                                }
                                                disabled={this.state.disabled}
                                              >
                                                <em className="icon ni ni-note-add"></em>
                                                {"  "}Save Theme
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-3">
                                      <div className="row">
                                        <h3
                                          style={{
                                            color: "#526484",
                                            fontSize: "26px",
                                            fontWeight: "500",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          Update Services
                                        </h3>
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          {this.state.getServiceErrorMessage !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{
                                                marginBottom: "15px",
                                                color: "#526484",
                                              }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    Error
                                                  </h4>
                                                  <p
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .getServiceErrorMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          {this.state
                                            .tableLoaderBlockService ===
                                          true ? (
                                            tableLoaderBlockImage()
                                          ) : (
                                            <>
                                              {this.state.serviceData &&
                                              this.state.serviceData.length >
                                                0 ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    {/** NEW SERVICE BLOCK **/}
                                                    <div className="nk-block">
                                                      {this.state.serviceData.map(
                                                        (services, sid) => (
                                                          <div
                                                            className={this.setServiceClassMain(
                                                              services.id
                                                            )}
                                                            key={`servKey${sid}`}
                                                          >
                                                            <div className="row no-gutters">
                                                              <div className="col-md-8">
                                                                <div className="sp-plan-info card-inner">
                                                                  <div className="row gx-0 gy-3">
                                                                    <div className="col-xl-9 col-sm-8">
                                                                      <div className="sp-plan-name">
                                                                        <h6 className="title">
                                                                          <a
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                              color:
                                                                                "#6576ff",
                                                                            }}
                                                                          >
                                                                            {
                                                                              services.service_title
                                                                            }{" "}
                                                                            <span className="badge badge-outline-primary">
                                                                              {services.service_type ===
                                                                              "did"
                                                                                ? "DID Origination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "tfn"
                                                                                ? "Toll Free Origination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "term"
                                                                                ? "Voice Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "teams"
                                                                                ? "Microsoft Teams UCAAS"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "ivr"
                                                                                ? "Voyce Admins"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "tfn_term"
                                                                                ? "Toll Free Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "az_term"
                                                                                ? "International A-Z Termination"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "live_agent"
                                                                                ? "Live Agent"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "live_agent"
                                                                                ? "CNAM"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "sms"
                                                                                ? "SMS Inbound / Outbound"
                                                                                : ""}
                                                                              {services.service_type ===
                                                                              "cnam"
                                                                                ? "CNAM"
                                                                                : ""}
                                                                            </span>
                                                                          </a>
                                                                        </h6>
                                                                        <p>
                                                                          Type:{" "}
                                                                          <span className="text-base">
                                                                            {services.per_min_type ===
                                                                            "unmetered"
                                                                              ? "Unmetered"
                                                                              : "Metered"}
                                                                          </span>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                    <div className="col-xl-3 col-sm-4"></div>
                                                                  </div>
                                                                </div>
                                                                <div className="sp-plan-desc card-inner">
                                                                  {services.service_type ===
                                                                    "did" ||
                                                                  services.service_type ===
                                                                    "tfn" ||
                                                                  services.service_type ===
                                                                    "teams" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            NRC{" "}
                                                                            <Tooltip
                                                                              title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.nrc &&
                                                                          services.nrc !==
                                                                            null &&
                                                                          services.nrc !==
                                                                            ""
                                                                            ? services.nrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            MRC{" "}
                                                                            <Tooltip
                                                                              title="Monthly Recurring Charge - what you pay each month for the service."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.mrc &&
                                                                          services.mrc !==
                                                                            null &&
                                                                          services.mrc !==
                                                                            ""
                                                                            ? services.mrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      {services.main_type !==
                                                                        "ratedeck" &&
                                                                      services.per_min_type !==
                                                                        "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Minute{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per minute."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.per_min_type ===
                                                                      "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Session{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per session."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.main_type ===
                                                                      "ratedeck" ? (
                                                                        <>
                                                                          <li className="col-6 col-lg-3">
                                                                            <p>
                                                                              <span className="text-soft">
                                                                                Effective
                                                                                Date{" "}
                                                                                <Tooltip
                                                                                  title="Effective Date."
                                                                                  placement="top"
                                                                                >
                                                                                  <em
                                                                                    className="icon ni ni-info"
                                                                                    style={{
                                                                                      color:
                                                                                        "#6576ff",
                                                                                    }}
                                                                                  ></em>
                                                                                </Tooltip>
                                                                              </span>{" "}
                                                                              {services.effective_date &&
                                                                              services.effective_date !==
                                                                                null &&
                                                                              services.effective_date !==
                                                                                "" ? (
                                                                                <>
                                                                                  {this.format_date(
                                                                                    services.effective_date
                                                                                  )}
                                                                                </>
                                                                              ) : null}
                                                                            </p>
                                                                          </li>
                                                                        </>
                                                                      ) : null}
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                    "term" ||
                                                                  services.service_type ===
                                                                    "ivr" ||
                                                                  services.service_type ===
                                                                    "live_agent" ||
                                                                  services.service_type ===
                                                                    "tfn_term" ||
                                                                  services.service_type ===
                                                                    "az_term" ? (
                                                                    <ul className="row gx-1">
                                                                      {services.main_type !==
                                                                        "ratedeck" &&
                                                                      services.per_min_type !==
                                                                        "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Minute{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per minute."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.per_min_type ===
                                                                      "unmetered" ? (
                                                                        <li className="col-6 col-lg-3">
                                                                          <p>
                                                                            <span className="text-soft">
                                                                              Rate
                                                                              Per
                                                                              Session{" "}
                                                                              <Tooltip
                                                                                title="Rate assigned per session."
                                                                                placement="top"
                                                                              >
                                                                                <em
                                                                                  className="icon ni ni-info"
                                                                                  style={{
                                                                                    color:
                                                                                      "#6576ff",
                                                                                  }}
                                                                                ></em>
                                                                              </Tooltip>
                                                                            </span>{" "}
                                                                            $
                                                                            {services.rate &&
                                                                            services.rate !==
                                                                              null &&
                                                                            services.rate !==
                                                                              ""
                                                                              ? services.rate.toFixed(
                                                                                  4
                                                                                )
                                                                              : "0.00"}
                                                                          </p>
                                                                        </li>
                                                                      ) : null}
                                                                      {services.main_type ===
                                                                      "ratedeck" ? (
                                                                        <>
                                                                          <li className="col-6 col-lg-3">
                                                                            <p>
                                                                              <span className="text-soft">
                                                                                Effective
                                                                                Date{" "}
                                                                                <Tooltip
                                                                                  title="Effective Date."
                                                                                  placement="top"
                                                                                >
                                                                                  <em
                                                                                    className="icon ni ni-info"
                                                                                    style={{
                                                                                      color:
                                                                                        "#6576ff",
                                                                                    }}
                                                                                  ></em>
                                                                                </Tooltip>
                                                                              </span>{" "}
                                                                              {services.effective_date &&
                                                                              services.effective_date !==
                                                                                null &&
                                                                              services.effective_date !==
                                                                                "" ? (
                                                                                <>
                                                                                  {this.format_date(
                                                                                    services.effective_date
                                                                                  )}
                                                                                </>
                                                                              ) : null}
                                                                            </p>
                                                                          </li>
                                                                        </>
                                                                      ) : null}
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                  "sms" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            SMS
                                                                            IN{" "}
                                                                            <Tooltip
                                                                              title="Price applied to SMS In Services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.sms_in &&
                                                                          services.sms_in !==
                                                                            null &&
                                                                          services.sms_in !==
                                                                            ""
                                                                            ? services.sms_in.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            SMS
                                                                            OUT{" "}
                                                                            <Tooltip
                                                                              title="Price applied to SMS Out Services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.sms_out &&
                                                                          services.sms_out !==
                                                                            null &&
                                                                          services.sms_out !==
                                                                            ""
                                                                            ? services.sms_out.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                  {services.service_type ===
                                                                  "cnam" ? (
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Update
                                                                            CNAM
                                                                            (MRC){" "}
                                                                            <Tooltip
                                                                              title="Price applied to Update CNAM (MRC)."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_mrc &&
                                                                          services.cnam_mrc !==
                                                                            null &&
                                                                          services.cnam_mrc !==
                                                                            ""
                                                                            ? services.cnam_mrc.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            CNAM
                                                                            DIP{" "}
                                                                            <Tooltip
                                                                              title="Price applied to CNAM DIP."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_dip &&
                                                                          services.cnam_dip !==
                                                                            null &&
                                                                          services.cnam_dip !==
                                                                            ""
                                                                            ? services.cnam_dip.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Storage
                                                                            DIP{" "}
                                                                            <Tooltip
                                                                              title="Price applied to Storage DIP."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cnam_dip_local &&
                                                                          services.cnam_dip_local !==
                                                                            null &&
                                                                          services.cnam_dip_local !==
                                                                            ""
                                                                            ? services.cnam_dip_local.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-3">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Cancellation
                                                                            Fee{" "}
                                                                            <Tooltip
                                                                              title="A cancellation fee is a sum of money charged to a customer who has enlisted your services."
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {services.cancellation_fee &&
                                                                          services.cancellation_fee !==
                                                                            null &&
                                                                          services.cancellation_fee !==
                                                                            ""
                                                                            ? services.cancellation_fee.toFixed(
                                                                                4
                                                                              )
                                                                            : "0.00"}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                              <div className="col-md-4">
                                                                <div className="sp-plan-action card-inner">
                                                                  <div className="sp-plan-btn">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.setService(
                                                                          services.id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      className={this.setServiceClass(
                                                                        services.id
                                                                      )}
                                                                    >
                                                                      <span>
                                                                        {this.state.selected_service.includes(
                                                                          services.id
                                                                        ) ? (
                                                                          <em className="icon ni ni-done"></em>
                                                                        ) : null}
                                                                        {"  "}
                                                                        CHOOSE
                                                                        SERVICE
                                                                      </span>
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    {/** NEW SERVICE BLOCK **/}
                                                  </div>
                                                </div>
                                              ) : null}
                                              <div
                                                className="form-group col-md-12 col-lg-12"
                                                style={{
                                                  marginTop: "20px",
                                                  paddingLeft: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {this.state.serviceData &&
                                                this.state.serviceData.length >
                                                  0 ? (
                                                  <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary"
                                                    onClick={
                                                      this.saveSharelinkServices
                                                    }
                                                    disabled={
                                                      this.state.disabled
                                                    }
                                                  >
                                                    <em className="icon ni ni-note-add"></em>
                                                    {"  "}Save Services
                                                  </button>
                                                ) : null}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-33">
                                      <div className="row">
                                        <h3
                                          style={{
                                            color: "#526484",
                                            fontSize: "26px",
                                            fontWeight: "500",
                                            marginBottom: "20px",
                                          }}
                                        >
                                          Update Bundles
                                        </h3>
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          {this.state.getBundleErrorMessage !==
                                          "" ? (
                                            <div
                                              className="example-alert"
                                              style={{
                                                marginBottom: "15px",
                                                color: "#526484",
                                              }}
                                            >
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h4
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    Error
                                                  </h4>
                                                  <p
                                                    style={{
                                                      color: "#364a63",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .getBundleErrorMessage
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                          {this.state.tableLoaderBlockBundle ===
                                          true ? (
                                            tableLoaderBlockImage()
                                          ) : (
                                            <>
                                              {this.state.bundlesData &&
                                              this.state.bundlesData.length >
                                                0 ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      {/** NEW SERVICE BLOCK **/}
                                                      <div className="nk-block">
                                                        {this.state.bundlesData.map(
                                                          (bundles, sid) => (
                                                            <div
                                                              className={this.setBundleClassMainNew(
                                                                bundles.id
                                                              )}
                                                              key={`servKey${sid}`}
                                                            >
                                                              <div className="row no-gutters">
                                                                <div className="col-md-8">
                                                                  <div className="sp-plan-info card-inner">
                                                                    <div className="row gx-0 gy-3">
                                                                      <div className="col-xl-9 col-sm-8">
                                                                        <div className="sp-plan-name">
                                                                          <h6 className="title">
                                                                            <a
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                color:
                                                                                  "#6576ff",
                                                                              }}
                                                                            >
                                                                              {
                                                                                bundles.bundle_title
                                                                              }{" "}
                                                                              <span className="badge badge-outline-primary"></span>
                                                                            </a>
                                                                          </h6>
                                                                          <p>
                                                                            Assigned
                                                                            Services:{" "}
                                                                            <span className="text-base">
                                                                              <span className="badge badge-outline-primary">
                                                                                VIEW
                                                                              </span>
                                                                            </span>
                                                                          </p>
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-xl-3 col-sm-4"></div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="sp-plan-desc card-inner">
                                                                    <ul className="row gx-1">
                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Per
                                                                            Month
                                                                            Rate
                                                                            (Charge
                                                                            Monthly){" "}
                                                                            <Tooltip
                                                                              title="Per Month Rate (Charge Monthly)"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {bundles.per_month_rate.toFixed(
                                                                            4
                                                                          )}
                                                                        </p>
                                                                      </li>
                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Per
                                                                            Month
                                                                            Rate
                                                                            (Charge
                                                                            Yearly)
                                                                            <Tooltip
                                                                              title="Per Month Rate (Charge Yearly)"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          $
                                                                          {bundles.per_year_rate.toFixed(
                                                                            4
                                                                          )}
                                                                        </p>
                                                                      </li>

                                                                      <li className="col-6 col-lg-4">
                                                                        <p>
                                                                          <span className="text-soft">
                                                                            Date
                                                                            Added
                                                                            <Tooltip
                                                                              title="Date Added"
                                                                              placement="top"
                                                                            >
                                                                              <em
                                                                                className="icon ni ni-info"
                                                                                style={{
                                                                                  color:
                                                                                    "#6576ff",
                                                                                }}
                                                                              ></em>
                                                                            </Tooltip>
                                                                          </span>{" "}
                                                                          {bundles.date_added !==
                                                                          ""
                                                                            ? this.format_date(
                                                                                bundles.date_added
                                                                              )
                                                                            : null}
                                                                        </p>
                                                                      </li>
                                                                    </ul>
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                  <div className="sp-plan-action card-inner">
                                                                    <div className="sp-plan-btn">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.setBundles(
                                                                            bundles.id
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        className={this.setBundleClassNew(
                                                                          bundles.id
                                                                        )}
                                                                      >
                                                                        <span>
                                                                          {this.state.selected_bundle.includes(
                                                                            bundles.id
                                                                          ) ? (
                                                                            <em className="icon ni ni-done"></em>
                                                                          ) : null}
                                                                          {"  "}
                                                                          CHOOSE
                                                                          BUNDLE
                                                                        </span>
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                      {/** NEW SERVICE BLOCK **/}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    {this.state.bundlesData &&
                                                    this.state.bundlesData
                                                      .length > 0 ? (
                                                      <button
                                                        type="button"
                                                        className="btn btn-lg btn-primary"
                                                        onClick={
                                                          this
                                                            .saveSharelinkBundles
                                                        }
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        <em className="icon ni ni-note-add"></em>
                                                        {"  "}Save Bundles
                                                      </button>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-4">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>About Me Details</h3>
                                          {this.state.tableLoaderBlockAbout ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getAboutErrorMessage !== "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getAboutErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getAboutErrorMessage === "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <FormControlLabel
                                                      style={{
                                                        marginBottom: "0px",
                                                        color: "#526484",
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                            this.state
                                                              .about_me_settings
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          name="about_me_settings"
                                                          id="about_me_settings"
                                                          color="primary"
                                                        />
                                                      }
                                                      label="About Me Selection"
                                                    />
                                                    {this.state
                                                      .about_me_settings ? (
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "30px",
                                                        }}
                                                      >
                                                        <div className="col-lg-12">
                                                          <h6
                                                            style={{
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            Short Description
                                                          </h6>
                                                        </div>
                                                        <div className="col-lg-12">
                                                          <TextareaAutosize
                                                            maxrows={4}
                                                            name="about_me_short"
                                                            id="about_me_short"
                                                            aria-label=""
                                                            placeholder=""
                                                            defaultValue={
                                                              this.state
                                                                .about_me_short
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              height: "100px",
                                                              borderColor:
                                                                "rgba(0, 0, 0, 0.125)",
                                                              borderRadius:
                                                                "4px",
                                                              padding: "5px",
                                                            }}
                                                            variant="outlined"
                                                          />
                                                        </div>
                                                        <div className="col-lg-12">
                                                          <h6
                                                            style={{
                                                              color: "#526484",
                                                            }}
                                                          >
                                                            About Me
                                                          </h6>
                                                        </div>

                                                        <div className="col-lg-12">
                                                          <div className="form-group">
                                                            <Editor
                                                              id="about_me_main"
                                                              name="about_me"
                                                              apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                                              initialValue={
                                                                this.state
                                                                  .about_me
                                                              }
                                                              selector="textarea#full-featured"
                                                              init={{
                                                                height: 500,
                                                                menubar: true,
                                                                plugins: [
                                                                  "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                                                ],
                                                                toolbar:
                                                                  "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                                              }}
                                                              onEditorChange={
                                                                this
                                                                  .handleEditorChange
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                    <div
                                                      className="form-group col-md-12 col-lg-12"
                                                      style={{
                                                        marginTop: "20px",
                                                        paddingLeft: "0px",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        className="btn btn-lg btn-primary"
                                                        onClick={
                                                          this
                                                            .saveSharelinkAbout
                                                        }
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        <em className="icon ni ni-note-add"></em>
                                                        {"  "}Save About Me
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-5">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Social Media Links</h3>
                                          {this.state.tableLoaderBlockSocial ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getSocialErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSocialErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getSocialErrorMessage ===
                                              "" ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <FormControlLabel
                                                        style={{
                                                          marginBottom: "0px",
                                                          color: "#526484",
                                                        }}
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              this.state
                                                                .social_settings
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            name="social_settings"
                                                            id="social_settings"
                                                            color="primary"
                                                          />
                                                        }
                                                        label="Social Media Links"
                                                      />
                                                      {this.state
                                                        .social_settings ? (
                                                        <div className="row g-4">
                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="instagram_link">
                                                                Instagram Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="instagram_link"
                                                                name="instagram_link"
                                                                value={
                                                                  this.state
                                                                    .instagram_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-instagram"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="facebook_link">
                                                                Facebook Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="facebook_link"
                                                                name="facebook_link"
                                                                value={
                                                                  this.state
                                                                    .facebook_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-facebook-fill"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="twitter_link">
                                                                Twitter Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="twitter_link"
                                                                name="twitter_link"
                                                                value={
                                                                  this.state
                                                                    .twitter_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-twitter-round"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={90}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="linkedin_link">
                                                                Linkedin Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="linkedin_link"
                                                                name="linkedin_link"
                                                                value={
                                                                  this.state
                                                                    .linkedin_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-linkedin-round"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={100}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="youtube_link">
                                                                Youtube Link
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="youtube_link"
                                                                name="youtube_link"
                                                                value={
                                                                  this.state
                                                                    .youtube_link
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-youtube"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                            </FormControl>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this.saveSharelinkSocial
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Social Media
                                                      Links
                                                    </button>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="tab-6">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>Contact Details</h3>
                                          {this.state
                                            .tableLoaderBlockContact ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state
                                                .getContactErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getContactErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state
                                                .getContactErrorMessage ===
                                              "" ? (
                                                <>
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <FormControlLabel
                                                        style={{
                                                          marginBottom: "0px",
                                                          color: "#526484",
                                                        }}
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              this.state
                                                                .contact_settings
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            name="contact_settings"
                                                            id="contact_settings"
                                                            color="primary"
                                                          />
                                                        }
                                                        label="Contact Settings"
                                                      />
                                                      {this.state
                                                        .contact_settings ? (
                                                        <div className="row g-4">
                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="contact_email">
                                                                Email Address
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="contact_email"
                                                                name="contact_email"
                                                                value={
                                                                  this.state
                                                                    .contact_email
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-emails"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={110}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="contact_phone">
                                                                Phone Number
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="contact_phone"
                                                                name="contact_phone"
                                                                type="number"
                                                                inputProps={{
                                                                  maxLength: 14,
                                                                }}
                                                                value={
                                                                  this.state
                                                                    .contact_phone
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-call-alt"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={115}
                                                              />
                                                            </FormControl>
                                                          </div>

                                                          <div className="col-lg-12">
                                                            <FormControl
                                                              fullWidth
                                                              variant="outlined"
                                                            >
                                                              <InputLabel htmlFor="contact_address">
                                                                Address
                                                              </InputLabel>
                                                              <OutlinedInput
                                                                id="contact_address"
                                                                name="contact_address"
                                                                value={
                                                                  this.state
                                                                    .contact_address
                                                                }
                                                                onChange={
                                                                  this
                                                                    .handleChange
                                                                }
                                                                startAdornment={
                                                                  <InputAdornment position="start">
                                                                    <em className="icon ni ni-home"></em>
                                                                  </InputAdornment>
                                                                }
                                                                labelWidth={70}
                                                              />
                                                            </FormControl>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="form-group col-md-12 col-lg-12"
                                                    style={{
                                                      marginTop: "20px",
                                                      paddingLeft: "0px",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this
                                                          .saveSharelinkContact
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Social Media
                                                      Links
                                                    </button>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane" id="tab-7">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 col-md-12 col-xl-12 details"
                                          style={{
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <h3>SEO Details</h3>
                                          {this.state.tableLoaderBlockSEO ===
                                          true ? (
                                            tableLoaderSimple()
                                          ) : (
                                            <>
                                              {this.state.getSeoErrorMessage !==
                                              "" ? (
                                                <div
                                                  className="example-alert"
                                                  style={{
                                                    marginBottom: "15px",
                                                    color: "#526484",
                                                  }}
                                                >
                                                  <div className="alert alert-pro alert-danger">
                                                    <div className="alert-text">
                                                      <h4
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        Error
                                                      </h4>
                                                      <p
                                                        style={{
                                                          color: "#364a63",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .getSeoErrorMessage
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                              {this.state.getSeoErrorMessage ===
                                              "" ? (
                                                <div className="card card-bordered">
                                                  <div className="card-inner">
                                                    <div
                                                      className="row g-4"
                                                      style={{
                                                        padding: "30px",
                                                      }}
                                                    >
                                                      <div className="form-group col-md-12 col-lg-12 col-xxl-12">
                                                        <MutextField
                                                          required
                                                          id="meta_title"
                                                          name="meta_title"
                                                          type="text"
                                                          label="Sharelink SEO Title"
                                                          inputProps={{
                                                            maxLength: 100,
                                                          }}
                                                          value={
                                                            this.state
                                                              .meta_title
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          helperText="Enter the SEO title here."
                                                          variant="outlined"
                                                          fullWidth
                                                        />
                                                      </div>
                                                      <div className="col-lg-12">
                                                        <h6
                                                          style={{
                                                            color: "#526484",
                                                          }}
                                                        >
                                                          Meta Description
                                                        </h6>
                                                      </div>
                                                      <div className="col-lg-12">
                                                        <TextareaAutosize
                                                          maxrows={4}
                                                          name="meta_description"
                                                          id="meta_description"
                                                          aria-label=""
                                                          placeholder=""
                                                          value={
                                                            this.state
                                                              .meta_description
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            height: "100px",
                                                            borderColor:
                                                              "rgba(0, 0, 0, 0.125)",
                                                            borderRadius: "4px",
                                                            padding: "15px",
                                                          }}
                                                          variant="outlined"
                                                        />
                                                      </div>
                                                      <div
                                                        className="form-group col-md-12 col-lg-12"
                                                        style={{
                                                          marginTop: "20px",
                                                          paddingLeft: "0px",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        <button
                                                          type="button"
                                                          className="btn btn-lg btn-primary"
                                                          onClick={
                                                            this
                                                              .saveSharelinkSEO
                                                          }
                                                          disabled={
                                                            this.state.disabled
                                                          }
                                                        >
                                                          <em className="icon ni ni-note-add"></em>
                                                          {"  "}Save SEO Details
                                                          Links
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="tab-pane" id="tab-8">
                                      <div className="row">
                                        <div
                                          className="col-lg-12 details"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <h3>Update Image Library</h3>
                                          <div
                                            className="col-md-12 col-lg-12"
                                            style={{ paddingLeft: "0px" }}
                                          >
                                            {this.state.getLogoErrorMessage !==
                                            "" ? (
                                              <div
                                                className="example-alert"
                                                style={{
                                                  marginBottom: "15px",
                                                  color: "#526484",
                                                }}
                                              >
                                                <div className="alert alert-pro alert-danger">
                                                  <div className="alert-text">
                                                    <h4
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      Error
                                                    </h4>
                                                    <p
                                                      style={{
                                                        color: "#364a63",
                                                      }}
                                                    >
                                                      {
                                                        this.state
                                                          .getLogoErrorMessage
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state.tableLoaderBlockLogo ===
                                            true ? (
                                              tableLoaderBlockImage()
                                            ) : (
                                              <>
                                                {this.state.logoData &&
                                                this.state.logoData.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h3
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                            }}
                                                          >
                                                            Select Logo
                                                          </h3>
                                                        </div>
                                                        {this.state.logoData.map(
                                                          (images) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`logos${images.id}`}
                                                            >
                                                              <div
                                                                className={this.setLogoClassMain(
                                                                  images.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    height:
                                                                      "120px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      verticalAlign:
                                                                        "middle",
                                                                      display:
                                                                        "inline-block",
                                                                      height:
                                                                        "100%",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${images.picture}`}
                                                                  />
                                                                </div>

                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.setLogo(
                                                                      images.id
                                                                    )
                                                                  }
                                                                  className={this.setLogoClass(
                                                                    images.id
                                                                  )}
                                                                >
                                                                  {this.state
                                                                    .selected_logo ===
                                                                  images.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  {"  "}
                                                                  Choose Logo
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}

                                                {this.state.avatarData &&
                                                this.state.avatarData.length >
                                                  0 ? (
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div
                                                        className="row g-4"
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                                          <h3
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                            }}
                                                          >
                                                            Select Avatar
                                                          </h3>
                                                        </div>
                                                        {this.state.avatarData.map(
                                                          (avatar) => (
                                                            <div
                                                              className="col-md-4 col-lg-4 col-xxl-4 col-sm-6"
                                                              style={{
                                                                float: "left",
                                                              }}
                                                              key={`avatar${avatar.id}`}
                                                            >
                                                              <div
                                                                className={this.setAvatarClassMain(
                                                                  avatar.id
                                                                )}
                                                              >
                                                                <div
                                                                  className="pricingTable-header"
                                                                  style={{
                                                                    minHeight:
                                                                      "150px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      height:
                                                                        "150px",
                                                                    }}
                                                                    src={`${APP_LIVE_URL}files_data/sharelinks/${avatar.picture}`}
                                                                  />
                                                                </div>

                                                                <a
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    paddingLeft:
                                                                      "15px",
                                                                    paddingRight:
                                                                      "15px",
                                                                  }}
                                                                  className={this.setAvatarClass(
                                                                    avatar.id
                                                                  )}
                                                                  onClick={() =>
                                                                    this.setAvatar(
                                                                      avatar.id
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .selected_avatar ===
                                                                  avatar.id ? (
                                                                    <em className="icon ni ni-done"></em>
                                                                  ) : null}
                                                                  Choose Avatar
                                                                  <i className="fa fa-angle-right"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                <div
                                                  className="form-group col-md-12 col-lg-12"
                                                  style={{
                                                    marginTop: "20px",
                                                    paddingLeft: "0px",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  {this.state.logoData &&
                                                  this.state.logoData.length >
                                                    0 &&
                                                  this.state.avatarData &&
                                                  this.state.avatarData.length >
                                                    0 ? (
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={
                                                        this.saveSharelinkLogos
                                                      }
                                                      disabled={
                                                        this.state.disabled
                                                      }
                                                    >
                                                      <em className="icon ni ni-note-add"></em>
                                                      {"  "}Save Images
                                                    </button>
                                                  ) : null}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}

              {/* Contact Logs MODAL Start */}
              <div className="modal fade" tabIndex="-1" id="modalContactLogs">
                <div
                  className="modal-dialog  modal-xl modal-dialog-top new_modal_width"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Contact Logs</h5>
                    </div>
                    <div className="modal-body">
                      <DataTableSmall
                        columns={this.state.columnContactLogs}
                        tableData={this.state.tableDataContactLogs}
                        title="Sharelink Contact Logs"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Logs MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalConfirm">
                <div
                  className="modal-dialog modal-dialog-top  modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Connect Domain</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group">
                        {this.state.available_domains.length > 0 ? (
                          <MutextField
                            id="domain_id"
                            name="domain_id"
                            select
                            label="Select Domain"
                            value={this.state.domain_id}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please select the domain to connect the sharelink."
                            variant="outlined"
                            fullWidth
                          >
                            <option key="" value=""></option>
                            {this.state.available_domains.map((domains) => (
                              <option value={domains[0]}>{domains[2]}</option>
                            ))}
                          </MutextField>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="modal-footer bg-light"
                      style={{
                        float: "right",
                        textAlign: "right",
                        justifyContent: "flex-end",
                      }}
                    >
                      <a
                        href="#"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </a>
                      {this.state.confirm_status !==
                      "This domain is already connected." ? (
                        <>
                          {this.state.btn_loader === true ? (
                            <button
                              className="btn btn-dim btn-primary"
                              disabled
                            >
                              <em className="icon ni ni-swap"></em> Connect
                              <div
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </button>
                          ) : (
                            <a
                              onClick={() => {
                                this.connectDomainSubmit();
                              }}
                              className="btn btn-primary"
                              style={{ color: "#fff" }}
                            >
                              Connect
                            </a>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sharelink);
