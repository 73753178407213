import React, { Component, Fragment } from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LOGO_ROUND from "../../assets/images/logo_round.png";
import { COMPANY_NAME, COMPANY_WEBSITE } from "../../config/config";

export class GetConsent extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      successMessage: "",
      tableLoader: false,
      dnsList: "",
    };
  }

  continue = (e) => {
    e.preventDefault();
    //PROCESS FORM//
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = async () => {
    const { values, submitConsent } = this.props;
    await submitConsent();
  };
  first = () => {
    setTimeout(() => {
      this.props.firstStep();
    }, 500);
  };
  verificationResult = () => {
    console.log("dnsList.DNSData.dnsRecords", this.props);
    /*if (dnsList.ErrorMessage) {
      return (
        <div className="callout callout-danger">
          <h4>Error verifying the domain</h4>
          {dnsList.ErrorMessage.msg}
        </div>
      );
    } else if (dnsList.DNSData.dnsRecords) {
      return (
        <>
          {dnsList.DNSData.dnsRecords.map((dns) => (
            <p>{dns.type}</p>
          ))}
        </>
      );
    }*/
  };

  render() {
    const { values, handleChange } = this.props;

    //console.log("Verification dnsList: ", values.selected_email);

    return (
      <>
        <div className="nk-block-head justify-content-center  mb-1">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title text-center">
              {values.reseller_comp} wants to access your {COMPANY_NAME} Account
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-center  mb-4">
          <div className="user-card bg-lighter p-3 rounded-pill">
            <div className="user-avatar" style={{ background: "#fff" }}>
              <img src={LOGO_ROUND} alt="" />
            </div>
            <div className="user-name">
              <span
                className="tb-lead fs-16px"
                style={{ fontFamily: "Nunito, sans-serif" }}
              >
                {values.selected_email}
              </span>
            </div>
          </div>
        </div>
        <div className="nk-block-head mb-1">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h6 className="nk-block-title">
              Because you're using Sign in with {COMPANY_NAME},{" "}
              {values.reseller_comp} will b able to
            </h6>
          </div>
        </div>
        <div className="card card-full">
          <div className="card-inner-group">
            <div
              className="card-inner card-inner-md"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <div className="review-item d-flex justify-content-between">
                <div className="user-card">
                  <div className="user-avatar bg-primary-dim">
                    <span>
                      <em className="icon ni ni-unlock"></em>
                    </span>
                  </div>
                  <div className="user-info">
                    <span className="lead-text fw-normal">
                      See your personel information and profile data
                    </span>
                  </div>
                </div>
                <div className="review-status text-right">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      checked={values.personal_information}
                      name="personal_information"
                      className="custom-control-input"
                      id="customCheck1"
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-inner card-inner-md"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <div className="review-item d-flex justify-content-between">
                <div className="user-card">
                  <div className="user-avatar bg-info-dim">
                    <span>
                      <em className="icon ni ni-unlock"></em>
                    </span>
                  </div>
                  <div className="user-info">
                    <span className="lead-text fw-normal">
                      See your billing information and payment methods
                    </span>
                  </div>
                </div>
                <div className="review-status justify-content-end">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      checked={values.billing_information}
                      className="custom-control-input"
                      name="billing_information"
                      id="customCheck2"
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck2"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-inner card-inner-md"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <div className="review-item d-flex justify-content-between">
                <div className="user-card">
                  <div className="user-avatar bg-warning-dim">
                    <span>
                      <em className="icon ni ni-unlock"></em>
                    </span>
                  </div>
                  <div className="user-info">
                    <span className="lead-text fw-normal">
                      Access your numbers view, orders and trunk management
                    </span>
                  </div>
                </div>
                <div className="review-status">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="number_information"
                      checked={values.number_information}
                      className="custom-control-input"
                      id="customCheck3"
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck3"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block-head mb-1">
          <div className="nk-block-head-content">
            <h6 className="nk-block-title">
              Make sure you trust {values.reseller_comp}{" "}
            </h6>
            <p>
              You may be sharing sensitive info with this site or app. You can
              always see or remove access in your{" "}
              <a href={COMPANY_WEBSITE} target="_blank">
                {COMPANY_NAME} Account
              </a>
              .
            </p>
            <p>
              See {values.reseller_comp}{" "}
              {values.privacy_url && values.privacy_url !== "" ? (
                <>
                  <a href={values.privacy_url} target="_blank">
                    Privacy Policy
                  </a>{" "}
                  and
                </>
              ) : null}
              {values.terms_url && values.terms_url !== "" ? (
                <a href={values.terms_url} target="_blank">
                  {" "}
                  Terms of Service
                </a>
              ) : null}
            </p>
            <p></p>
          </div>
        </div>
        <div className="row g-gs">
          <div className="col-md-6">
            {!values.verifyConsentButton ? (
              <button
                className="btn btn-lg btn-outline-light btn-block"
                onClick={this.first}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
            ) : null}
          </div>
          <div className="col-md-6">
            {values.verifyConsentButton ? (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                className="btn btn-lg btn-primary btn-block"
                onClick={this.submitForm}
              >
                Continue
                <em className="icon ni ni-done"></em>
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default GetConsent;
