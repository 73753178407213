import { Link } from "react-router-dom";
import { LOGO } from "./../../config/config";
import $ from "jquery";
import HelperClass from "./../../config/helperClass";
const auth = new HelperClass();
const leftSideBar = (props) => {
  console.log("PROPS: ", props);
  function classCheck() {
    var menu_active = $(this);
    var menu_items = $("#reportmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function aClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#agreementmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function shareClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#sharemenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function ClientClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#clientmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function classCheck2() {
    var menu_active = $(this);
    console.log("menu_active: ", menu_active);
    var menu_items = $("#webmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function activeCheck() {
    var menu_active = $(this);
    console.log("menu active has: ", menu_active);
    if (menu_active.hasClass("active")) {
      $(this).removeClass("active");
      $(this).removeClass("current-page");
    } else {
      $(this).addClass("active");
      $(this).addClass("current-page");
    }
  }

  const pageviewcheck = () => {
    var pageview = props.mainProps.view;
    if (pageview == "editor") {
      var result = '{{display:"none"}}';
    } else {
      var result = "{{}}";
    }
    return result;
  };

  const dashboardLeft = () => {
    props.mainProps.history.push("/dashboard");
  };

  const clientsLeft = () => {
    props.mainProps.history.push("/clients");
  };

  const servicesLeft = () => {
    props.mainProps.history.push("/services");
  };

  const agentsLeft = () => {
    props.mainProps.history.push("/agents");
  };

  const sharelinksLeft = () => {
    props.mainProps.history.push("/sharelinks");
  };

  const shareLinkSetting = () => {
    props.mainProps.history.push("/sharelinks/sharelink_settings");
  };

  const reportsGetDailyOrigStats = () => {
    props.mainProps.history.push("/reports/get_daily_orig_stats");
  };

  const reportsGetDailyTermStats = () => {
    props.mainProps.history.push("/reports/get_daily_term_stats");
  };

  const reportsGetDailySmsStats = () => {
    props.mainProps.history.push("/reports/get_daily_sms_stats");
  };

  const reportsGetDailyDidStats = () => {
    props.mainProps.history.push("/reports/get_daily_did_stats");
  };

  const reportsGetDailyRevenue = () => {
    props.mainProps.history.push("/reports/get_daily_revenue");
  };

  const reportsGetAggOrigStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_orig_stats");
  };

  const reportsGetAggTermStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_term_stats");
  };

  const reportsGetAggSmsStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_sms_stats");
  };

  const reportsGetAggDidStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_did_stats");
  };

  const reportsGetAggRevenueStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_revenue_stats");
  };

  const analyticsLeft = () => {
    props.mainProps.history.push("/analytics");
  };

  const agreementsLeft = () => {
    props.mainProps.history.push("/agreements");
  };

  const unsignedagreementsLeft = () => {
    props.mainProps.history.push("/agreements/unsignedAgreements");
  };

  const cmsLeft = () => {
    props.mainProps.history.push("/cms");
  };

  const domainsLeft = () => {
    props.mainProps.history.push("/domains");
  };

  const themesLeft = () => {
    props.mainProps.history.push("/themes");
  };

  const upgradesLeft = () => {
    props.mainProps.history.push("/upgrades");
  };

  const navigate = (link) => {
    props.mainProps.history.push("/" + link);
  };

  const navigateAdmin = (link) => {
    props.mainAdminProps.history.push("/" + link);
  };

  const routingLeft = () => {
    props.mainProps.history.push("/upgrades");
  };

  return (
    <div className="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          minHeight: "68px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            {auth.getResellerLogo() !== "" ? (
              <>
                <img
                  className="logo-light logo-img"
                  src={auth.getResellerLogo()}
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img"
                  src={auth.getResellerLogo()}
                  alt="logo-dark"
                  style={{ marginLeft: "0" }}
                />
              </>
            ) : (
              <>
                <img className="logo-light logo-img" src={LOGO} alt="logo" />
                <img
                  className="logo-dark logo-img"
                  src={LOGO}
                  alt="logo-dark"
                  style={{ marginLeft: "0" }}
                />
              </>
            )}
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#6576ff" }}
                >
                  Agent Portal
                </h3>
              </li>
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#202223" }}
                >
                  {auth.getCompName()}
                </h3>
              </li>
              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => dashboardLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-dashboard"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    Dashboard
                  </span>
                </Link>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => ClientClassCheck()}
                id="clientmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-user-list"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Clients
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => clientsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user-list"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Clients
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigate("clients/clients_queue")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-row-view"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Client Requests
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => servicesLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-call-fill"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Services
                  </span>
                </Link>
              </li>

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => agentsLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-users"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Sub Agents
                  </span>
                </Link>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => shareClassCheck()}
                id="sharemenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-share"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Sharelinks
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => sharelinksLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-share-alt"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Sharelinks
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => shareLinkSetting()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-setting"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Sharelink Settings
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => domainsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-link"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Sharelink Domains
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => classCheck()}
                id="reportmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Reports
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyOrigStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-row-view"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Orig. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyTermStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-call-alt"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Term. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailySmsStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-chat"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily SMS Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyDidStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-row-view"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily DID Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetDailyRevenue()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-report-profit"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Daily Revenue
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggOrigStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-row-view"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Orig. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggTermStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-call-alt"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Term. Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggSmsStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-chat"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate SMS Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggDidStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-row-view"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate DID Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => reportsGetAggRevenueStats()}
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-report-profit"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Aggregate Revenue
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nk-menu-item">
                <Link className="nk-menu-link" onClick={() => analyticsLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-growth"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Analytics
                  </span>
                </Link>
              </li>

              {/*<li
                className="nk-menu-item has-sub"
                onClick={() => classCheck2()}
                id="webmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem", 
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Website
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link onClick={() => cmsLeft()} className="nk-menu-link">
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage CMS
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link onClick={() => themesLeft()} className="nk-menu-link">
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Themes
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      onClick={() => domainsLeft()}
                      className="nk-menu-link"
                    >
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Domains
                      </span>
                    </Link>
                  </li>
                </ul>
                  </li>*/}
              <li
                className="nk-menu-item has-sub"
                onClick={() => aClassCheck()}
                id="agreementmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Agreements
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => agreementsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-check-round-cut"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Signed Agreements
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => unsignedagreementsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-na"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Unsigned Agreements
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nk-menu-item">
                <Link className="nk-menu-link" onClick={() => upgradesLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-upload"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Settings
                  </span>
                </Link>
              </li>
              {props.routingUrl !== "" ? (
                <li className="nk-menu-item">
                  <Link
                    className="nk-menu-link"
                    onClick={() => {
                      window.open(props.routingUrl, "_blank");
                    }}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-arrow-right-c"></em>
                    </span>
                    <span
                      className="nk-menu-text"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Routing Portal
                    </span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default leftSideBar;
