import React, { Component, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MuiThemeProvider,
  TextField,
} from "@material-ui/core";
import MutextField from "@material-ui/core/TextField";
import { HELP_SUPPORT, CONTACT_US_URL } from "../../config/config";

export class TrunkForm extends Component {
  // first = (e) => {
  //   window.close();
  //   setTimeout(() => {
  //     //window.location.reload();
  //     //this.props.firstStep();
  //   }, 1000);
  // };
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: [],
    };
  }
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    //this.props.prevStep();
    window.location.reload();
  };

  submitForms = async () => {
    const { values, submitTrunk } = this.props;
    await submitTrunk();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <>
        <div className="nk-block-head justify-content-center  mb-4">
          <div className="nk-block-head-content text-center">
            {/* <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em> */}
            <h4 className="nk-block-title text-center mt-4">
              Origination Trunk
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-center"></div>
        <form method="post">
          <div className="row">
            <div className="col-lg-12">
              <TextField
                required
                id="trunkName"
                name="trunkName"
                type="text"
                className="mb-4"
                label="Trunk Group Name"
                defaultValue={values.trunkName}
                inputProps={{ maxLength: 50 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
                }}
                onChange={handleChange("trunkName")}
                helperText="Enter Trunk Group Name"
                variant="outlined"
                autoFocus
                fullWidth
              />
              <TextField
                required
                id="trunkIp"
                name="trunkIp"
                type="text"
                className="mb-4"
                label="IP / Hostname"
                defaultValue={values.trunkIp}
                inputProps={{ maxLength: 50 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z0-9. ]/g, "");
                }}
                onChange={handleChange("trunkIp")}
                helperText="Enter IP Address"
                variant="outlined"
                fullWidth
              />
            </div>
            {/* <div className="col-lg-6">
              <TextField
                required
                id="trunk_port"
                name="trunk_port"
                type="text"
                className="mb-4"
                label="Port"
                defaultValue={values.trunk_port}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                }}
                onChange={handleChange("trunk_port")}
                helperText="Enter Port"
                variant="outlined"
                fullWidth
              />
            </div> */}
            {/* <div className="col-lg-6">
              <TextField
                required
                id="trunk_cps"
                name="trunk_cps"
                type="text"
                className="mb-4"
                label="CPS"
                defaultValue={values.trunk_cps}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                }}
                onChange={handleChange("trunk_cps")}
                helperText="Enter CPS"
                variant="outlined"
                fullWidth
              />
            </div> */}
            {/* <div className="col-lg-6">
              <TextField
                required
                id="trunk_session"
                name="trunk_session"
                type="text"
                className="mb-4"
                label="Sessions"
                defaultValue={values.trunk_session}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                }}
                onChange={handleChange("trunk_session")}
                helperText="Enter Sessions"
                variant="outlined"
                fullWidth
              />
            </div> */}

            <div className="col-lg-12">
              <MutextField
                id="trunk_number_Format"
                name="trunk_number_Format"
                select
                label="Number Format"
                value={values.trunk_number_Format}
                onChange={handleChange("trunk_number_Format")}
                SelectProps={{
                  native: true,
                }}
                helperText="Number Format"
                variant="outlined"
                fullWidth
              >
                <option key="0" value="10DIGIT">
                  10 DIGIT
                </option>
                <option key="1" value="11DIGIT">
                  11 DIGIT
                </option>
                <option key="2" value="E164">
                  E164
                </option>
              </MutextField>
            </div>
          </div>

          <div className="form-group d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-lg btn-outline-light"
              disabled={this.state.disabled}
              onClick={this.back}
              style={{ marginRight: "10px" }}
            >
              Back
            </button>

            {values.create_trunk_button ? (
              <button
                className="btn btn-lg btn-primary "
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-lg btn-primary "
                disabled={this.state.disabled}
                onClick={this.submitForms}
              >
                Create
              </button>
            )}
          </div>
        </form>

        <div className="form-group d-flex justify-content-end mt-4">
          {/* <button
            className="btn btn-lg btn-primary btn-block"
            onClick={this.first}
            id="closePopup"
          >
            <em className="icon ni ni-first"></em>
            Go Back
          </button> */}
        </div>
      </>
    );
  }
}

export default TrunkForm;
