import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordStrengthBar from "react-password-strength-bar";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LOGO_ROUND from "../../assets/images/logo_round.png";
import {
  COMPANY_NAME,
  COMPANY_WEBSITE,
  RESELLER_TERMS_URL,
  RESELLER_PRIVACY_URL,
} from "../../config/config";

export class GetPassword extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      successMessage: "",
      tableLoader: false,
      dnsList: "",
      suggestions: [],
      showPassword: false,
      showConfirmPassword: false,
      accept_terms: "",
    };
  }

  continue = (e) => {
    e.preventDefault();
    //PROCESS FORM//
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = async () => {
    const { values, formSubmit } = this.props;
    await formSubmit();
  };

  handleClickShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({
        showPassword: false,
      });
    } else {
      this.setState({
        showPassword: true,
      });
    }
  };
  handleClickShowConfirmPassword = () => {
    if (this.state.showConfirmPassword) {
      this.setState({
        showConfirmPassword: false,
      });
    } else {
      this.setState({
        showConfirmPassword: true,
      });
    }
  };

  handleChanges = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  verificationResult = () => {
    console.log("dnsList.DNSData.dnsRecords", this.props);
    /*if (dnsList.ErrorMessage) {
      return (
        <div className="callout callout-danger">
          <h4>Error verifying the domain</h4>
          {dnsList.ErrorMessage.msg}
        </div>
      );
    } else if (dnsList.DNSData.dnsRecords) {
      return (
        <>
          {dnsList.DNSData.dnsRecords.map((dns) => (
            <p>{dns.type}</p>
          ))}
        </>
      );
    }*/
  };

  render() {
    const { values, handleChange, handleChanges } = this.props;

    //console.log("Verification dnsList: ", values.selected_email);

    return (
      <>
        <div className="nk-block-head justify-content-center  mb-1">
          <div className="nk-block-head-content  d-flex justify-content-center">
            <h4 className="nk-block-title text-center">Enter the password</h4>
          </div>
        </div>
        <div className="d-flex justify-content-center  mb-4">
          <div className="user-card bg-lighter p-3 rounded-pill">
            <div className="user-avatar" style={{ background: "#fff" }}>
              <img src={LOGO_ROUND} alt="" />
            </div>
            <div className="user-name">
              <span
                className="tb-lead fs-16px"
                style={{ fontFamily: "Nunito, sans-serif" }}
              >
                {values.selected_email}
              </span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              required
              id="password"
              name="password"
              type={this.state.showPassword ? "text" : "password"}
              defaultValue={values.password}
              inputProps={{ maxLength: 50, minLength: 8 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /[^0-9a-zA-Z@&*$%()+!]/gi,
                  ""
                );
              }}
              onChange={handleChange("password")}
              helperText="Minimum 8 to 50 characters"
              error={values.errorPassword}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <PasswordStrengthBar
            password={values.password}
            minLength={8}
            onChangeScore={(score, feedback) => {
              this.setState({
                suggestions: feedback.suggestions,
              });
              //console.log(score, feedback.suggestions);
            }}
          />
          {this.state.suggestions && this.state.suggestions.length > 0 ? (
            <>
              {this.state.suggestions.map((themes, index) => (
                <figcaption className="blockquote-footer" key={`keys-${index}`}>
                  {themes}
                </figcaption>
              ))}
            </>
          ) : null}
        </div>

        <div className="form-group">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              required
              id="confirm_password"
              name="confirm_password"
              type={this.state.showConfirmPassword ? "text" : "password"}
              defaultValue={values.confirm_password}
              inputProps={{ maxLength: 50, minLength: 8 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /[^0-9a-zA-Z@&*$%()+!]/gi,
                  ""
                );
              }}
              onChange={handleChange("confirm_password")}
              helperText="Minimum 8 to 50 characters"
              error={values.errorConfirmPassword}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {this.state.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
        </div>
        <hr />
        <div className="row g-gs">
          <div className="col-md-6">
            {!values.verifyDetailsButton ? (
              <button
                className="btn btn-lg btn-outline-light  btn-block"
                disabled={this.state.disabled}
                onClick={this.back}
                style={{ marginRight: "10px" }}
              >
                Back
              </button>
            ) : null}
          </div>
          <div className="col-md-6">
            {values.verifyDetailsButton ? (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Processing...</span>
              </button>
            ) : (
              <button
                className="btn btn-lg btn-primary btn-block"
                onClick={this.submitForm}
              >
                Continue
                <em className="icon ni ni-done"></em>
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default GetPassword;
